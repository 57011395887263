import { memo, createContext, useEffect } from 'react';

import queryString from 'query-string';
import { Navigate, useLocation } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import PageTitle from '@containers/common/PageTitle';
import { useAppSelector } from '@features/app/hooks';
import { getOpenJobsSelectedRow, selectOpenOrders } from '@features/orders/openOrders/selectors';
import { Box } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';
import { selectRowsArray } from '@features/orders/openOrders/slice';
import { useDispatch } from 'react-redux';
import { OrderStatusesEnum } from '@features/orders/order/enums';
import { usePrevScroll } from "@utils/prevState";

import { IFiltersForm } from './components/SearchSection/helpers';
import { ActionsWithJobs, OrdersListSection, SearchSection } from './components';
import { accessibilityList, getOrderKindByUrl, renderOrdersPageTitle } from './helper';
import { Colors, ColorItem } from './styled';

export const OrderKindsContext = createContext<OrderStatusesEnum | ''>('');

const Orders = () => {
  usePrevScroll();

  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const openJobsSelectedRows = useAppSelector(getOpenJobsSelectedRow);
  const { data: ordersList } = useAppSelector(selectOpenOrders);
  const params = queryString.parse(search);
  const { dateFrom = '', dateTo = '', status = '', searchTerm = '' } = params as IFiltersForm;
  const isSearchTerm = dateFrom || dateTo || status || searchTerm;

  // @ts-ignore
  if (pathname && !accessibilityList.includes(pathname)) {
    return <Navigate to={PAGE_ROUTES.OPEN_ORDERS} replace />;
  }

  const orderKind = getOrderKindByUrl(pathname);

  useEffect(() => {
    dispatch(selectRowsArray([]));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderKind]);

  return (
    <OrderKindsContext.Provider value={orderKind}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle
          title={renderOrdersPageTitle(orderKind)}
          btnName=""
          isShowBtn={false}
          path={PAGE_ROUTES.ADD_PRODUCTS}
        />
        <Colors>
          <ColorItem color={COLORS.white.main} background={COLORS.red.light}>Open Task</ColorItem>
          <ColorItem color="#504805" background={COLORS.white.dark}>Rush</ColorItem>
          <ColorItem color="#190550" background={COLORS.blue.light}>Full-Service EDDM</ColorItem>
          <ColorItem color="#500505" background={COLORS.red.exLight}>Comment</ColorItem>
        </Colors>
      </Box>
      {(isSearchTerm || !!ordersList.length) && <SearchSection />}
      {openJobsSelectedRows.length ? (<ActionsWithJobs />) : null}
      <OrdersListSection isSearchTerm={isSearchTerm} />
    </OrderKindsContext.Provider>
  );
};

export default memo(Orders);
