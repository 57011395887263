import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const SendEmailWrapper = styled(Box)(() => ({
  marginTop: '24px',
  gap: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  span: {
    padding: 0,
  },

  'label.MuiFormControlLabel-root': {
    marginRight: '12px',
  },
}));
