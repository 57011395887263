import { memo, useEffect, useState } from 'react';

import StyledTable from '@containers/common/Table';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import Loader from '@containers/common/Loader';
import PageTitle from '@containers/common/PageTitle';
import { StyledTableRow } from '@containers/common/Table/styled';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';
import EmptyState from '@containers/common/EmptyState';
import { EXTRA_DATE_FORMAT, priceFormatting } from '@utils/helpers';
import { selectInvoices } from '@features/invoices/selectors';
import { getOrderDetailsForInvoice } from '@features/orders/order/actions';
import { useNavigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import { buildRoute } from '@routes/helpers';
import { getInvoicesThunk } from '@features/invoices/actions';
import { format } from 'date-fns';
import { clearInvoices } from '@features/invoices/slice';
import { PrevState } from '@utils/prevState';

import { headCells } from './helpers';
import { StyledButton, StyledRedirectText } from './styled';
import { LOAD_MORE_LIMIT, LOAD_MORE_OFFSET } from './constants';

interface InvoicesProps {
  title: string;
  status: string;
  emptyText:string;
}

const Invoices = ({ title, status, emptyText }: InvoicesProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dateFormat = EXTRA_DATE_FORMAT;

  const {
    data: invoices,
    isLoading,
    loadMoreLoading,
    isHiddenLoadMoreButton,
  } = useAppSelector(selectInvoices);

  const [offsetCount, setOffsetCount] = useState(0);

  const fetchData = () => {
    dispatch(getInvoicesThunk({
      status,
      limit: LOAD_MORE_LIMIT,
      offset: (LOAD_MORE_OFFSET * offsetCount) || 0,
    }));
  };

  const startLoadMoreRequest = async () => {
    setOffsetCount((prev) => prev + 1);
  };

  const redirectTo = async (orderId: string, invoiceId?: string, invoiceStatus?: string) => {
    try {
      if (!orderId) {
        return;
      }

      const response = await dispatch(getOrderDetailsForInvoice({ id: orderId }))
        .unwrap();

      const jobId = response?.jobs[0]?.id;

      if (!jobId) {
        return;
      }

      if (invoiceId) {
        let url = buildRoute(PAGE_ROUTES.ORDER_EDIT_INVOICE, { orderId, jobId, invoiceId });

        if (invoiceStatus === 'paid') {
          url = buildRoute(PAGE_ROUTES.ORDER_INVOICE_VIEW, { orderId, jobId, invoiceId });
        }

        navigate(url);

        return;
      }

      const url = buildRoute(PAGE_ROUTES.ORDER, { orderId, jobId });

      navigate(url);
    } catch (error) {
      console.log('Error :', error);
    }
  };

  useEffect(
    () => {
      dispatch(clearInvoices());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );
  useEffect(
    () => {
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offsetCount, status],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle title={title} isShowBtn={false} />
      {invoices?.length ? (
        <StyledTable headCells={headCells}>
          {invoices?.map(({
            orderNumber,
            dateBilled,
            paymentMethod,
            orderId,
            created,
            invoiceStatus,
            billingStatus,
            subtotal,
            invoiceNumber,
            id,
          }) => (
            <StyledTableRow key={id}>
              <TableCell>
                <StyledRedirectText
                  onClick={() => {
                  redirectTo(orderId);
                  PrevState.set({ scroll: window?.scrollY });
                }}
                >
                  {orderNumber}
                </StyledRedirectText>
              </TableCell>
              <TableCell>
                <StyledRedirectText
                  onClick={() => {
                    redirectTo(orderId, id, invoiceStatus);
                    PrevState.set({ scroll: window?.scrollY });
                  }}
                >
                  {invoiceNumber}
                </StyledRedirectText>
              </TableCell>
              <TableCell>
                {created && format(new Date(created), dateFormat)}
              </TableCell>
              <TableCell>
                {invoiceStatus}
              </TableCell>
              {/* <TableCell>
                {billingStatus}
              </TableCell> */}
              <TableCell>
                {priceFormatting(subtotal)}
              </TableCell>
              <TableCell>
                {paymentMethod}
              </TableCell>
              <TableCell>
                {dateBilled && format(new Date(dateBilled), dateFormat)}
              </TableCell>
            </StyledTableRow>
          ))}
        </StyledTable>
      ) : (
        <EmptyState text={emptyText} />
      )}

      {
        loadMoreLoading ? <Loader /> : isHiddenLoadMoreButton ? null : (
          <Box sx={{ textAlign: 'center' }}>
            <StyledButton
              onClick={startLoadMoreRequest}
            >
              Load more
            </StyledButton>
          </Box>
        )
      }
    </>
  );
};

export default memo(Invoices);
