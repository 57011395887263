import { memo } from 'react';

import Button from '@containers/common/Button';
import { MenuItem, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { getOpenJobsSelectedRow } from '@features/orders/openOrders/selectors';
import StyledSearchSection from '@containers/common/StyledSearchContainer';
import { getOrderStatusesOptions } from '@utils/orderStatuses';
import { FormProvider, useForm } from 'react-hook-form';
import Select from '@containers/common/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrdersThunk, updateJobsStatuses } from '@features/orders/openOrders/actions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { GlobalQueryString } from '@utils/globalTypes';
import PAGE_ROUTES from '@routes/routingEnum';
import { OrderStatusesEnum } from '@features/orders/order/enums';
import { selectRowsArray } from '@features/orders/openOrders/slice';

import { UpdateOrderStatusSchema, UpdateStatusForm } from './helper';
import {
  StyledContainer,
  StyledBox,
  StyledSelect,
  StyledTypography,
  StyledLine,
} from './styled';

const ActionsWithJobs = () => {
  const dispatch = useAppDispatch();
  const statusOptions = getOrderStatusesOptions();
  const openJobsSelectedRows = useAppSelector(getOpenJobsSelectedRow);
  const { search, pathname } = useLocation();
  const params = queryString.parse(search) as GlobalQueryString;

  const methods = useForm<UpdateStatusForm>({
    resolver: yupResolver(UpdateOrderStatusSchema),
    defaultValues: { status: '' },
  });

  const { handleSubmit, formState: { errors }, reset } = methods;

  const onSubmit = async ({ status }: UpdateStatusForm) => {
    if (!status) {
      return null;
    }

    let fixedStatus = {};

    if (PAGE_ROUTES.COMPLETED_ORDERS === pathname) {
      fixedStatus = {
        status: OrderStatusesEnum.Complete,
      };
    } else if (PAGE_ROUTES.CANCELLED_ORDERS === pathname) {
      fixedStatus = {
        status: OrderStatusesEnum.Canceled,
      };
    }

    try {
      await dispatch(updateJobsStatuses({ status, ids: openJobsSelectedRows }));
      await dispatch(getOrdersThunk({
        ...params,
        offset: 0,
        limit: 8,
        ...fixedStatus,
      })).unwrap();

      dispatch(selectRowsArray([]));
      reset();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, '-----error');
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          marginBottom: '16px',
        }}
      >
        {openJobsSelectedRows.length}
        {' '}
        Items Selected
      </Typography>

      <StyledSearchSection>
        <StyledContainer>
          <StyledBox>
            <StyledTypography
              variant="body1"
            >
              Change status of all checked orders to
            </StyledTypography>
            <FormProvider {...methods}>
              <Stack
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                gap="24px"
              >
                <Select
                  name="status"
                  options={statusOptions}
                  errorMessage={errors?.status?.message as string}
                />
                <Button type="submit"> Updates Status </Button>
              </Stack>
            </FormProvider>
          </StyledBox>
          {/* <StyledLine /> */}
          {/* <StyledBox>
            <StyledTypography
              variant="body1"
              sx={{
                marginBottom: '56px',
              }}
            >
              Send an email status update to all checked jobs
            </StyledTypography>
            <Button disabled> Send Email Updates </Button>
          </StyledBox>
          <StyledLine /> */}
          {/* <StyledBox>
            <StyledTypography
              variant="body1"
            >
              Update all checked jobs with the following vendor
            </StyledTypography>
            <StyledSelect
              labelId=""
              id=""
              value="Test"
              label=""
              onChange={() => {
              }}
            >
              <MenuItem value="Test">Test</MenuItem>
              <MenuItem value="Test2">Test2</MenuItem>
              <MenuItem value="Test3">Test3</MenuItem>
            </StyledSelect>
            <Button disabled>Updates Vendor</Button>
          </StyledBox> */}
        </StyledContainer>
      </StyledSearchSection>
    </>
  );
};

export default memo(ActionsWithJobs);
