import { createSlice } from "@reduxjs/toolkit";

import { IOrder, IState } from "./types";
import { getOrderDetails, getOrderTasks, getOrderInvoices } from "./actions";

const initialState: IState = {
  isLoading: true,
  errorMessage: null,
  actionLoading: true,
  selectedRows: [],
  activeActionsRows: [],
  tasks: [],
  billingAddress: {},
  invoice: null,
  invoices: [],
  order: {} as IOrder,
};

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
   updateDropShipOrShipTo: (state, { payload }) => {
    // this update not connected with form state,
    // it only show updated view after closing popup, shipment separated from form
    const [jobIndex, shippingIndex, whichObject] = payload.link.split('-');

    if (whichObject === 'shipTo') {
      state.order.jobs[jobIndex].shipments[shippingIndex].shipTo = { ...payload.data };
    } else {
      state.order.jobs[jobIndex].shipments[shippingIndex].dropShip = { ...payload.data };
    }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, { payload }) => {
      state.order = payload;
      state.isLoading = false;
    });
    builder.addCase(getOrderDetails.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOrderTasks.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderTasks.fulfilled, (state, { payload }) => {
      state.tasks = payload;
      state.isLoading = false;
    });
    builder.addCase(getOrderTasks.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getOrderInvoices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrderInvoices.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.invoices = payload?.invoices;
      state.billingAddress = payload?.billingAddress;
    });
    builder.addCase(getOrderInvoices.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { updateDropShipOrShipTo } = orderSlice.actions;

export default orderSlice.reducer;
