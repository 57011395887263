import { Dispatch, SetStateAction } from 'react';

import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

export const modalWrapperStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 520,
  background: 'white',
  p: 4,
};

interface ConversationModalProps {
  isConversationOpen: boolean;
  setIsConversationOpen: Dispatch<SetStateAction<boolean>>;
}

const ConversationModal = ({ isConversationOpen, setIsConversationOpen }: ConversationModalProps) => {
  return (
    <Modal
      open={isConversationOpen}
      onClose={() => {
        setIsConversationOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalWrapperStyle}>
        aaa
      </Box>
    </Modal>
  );
};

export default ConversationModal;
