import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import auth from '@features/auth/slice';
import users from '@features/users/slice';
import categories from '@features/categories/slice';
import banners from '@features/banners/slice';
import subcategories from '@features/subcategories/slice';
import bestSellers from '@features/bestSellers/slice';
import attributeCategories from '@features/attributeCategories/slice';
import attributes from '@features/attributes/slice';
import templateCategories from '@features/templateCategories/slice';
import templates from '@features/templates/slice';
import products from '@features/products/basicInfo/slice';
import productsQuantities from '@features/products/productsQuantity/slice';
import productsAttributes from '@features/products/productsAttributes/slice';
import productsSetPrice from '@features/products/setPrice/slice';
import customers from '@features/customers/customers/slice';
import promoCodes from '@features/promoCodes/slice';
import blockedIps from '@features/customers/blockedIps/slice';
import billingInfo from '@features/customers/billingInfo/slice';
import tasks from '@features/customers/tasks/slice';
import developersTasks from '@features/customers/developerTasks/slice';
import requests from '@features/requests/slice';
import sidebarCounts from '@features/sidebarCounts/slice';
import contactFormMessages from '@features/contactFormMessages/slice';
import shippingZips from '@features/shippingZip/slice';
import shippingMarkupRate from '@features/shippingMarkupRate/slice';
import emailTemplates from '@features/emailTemplate/slice';
import customEstimates from '@features/customEstimates/slice';
import sendAFile from '@features/sendAFile/slice';
import adminLogs from '@features/adminLogIn/slice';
import mailingServiceFees from '@features/mailingServiceFees/slice';
import openOrders from '@features/orders/openOrders/slice';
import order from '@features/orders/order/slice';
import charities from '@features/giveBack/slice';
import vendors from '@features/vendors/slice';
import invoicesSlice from '@features/invoices/slice';

export const store = configureStore({
  reducer: {
    auth,
    users,
    categories,
    banners,
    subcategories,
    bestSellers,
    attributeCategories,
    attributes,
    templateCategories,
    templates,
    products,
    productsQuantities,
    productsAttributes,
    productsSetPrice,
    customers,
    promoCodes,
    blockedIps,
    billingInfo,
    tasks,
    developersTasks,
    requests,
    sidebarCounts,
    contactFormMessages,
    shippingZips,
    shippingMarkupRate,
    emailTemplates,
    customEstimates,
    sendAFile,
    adminLogs,
    mailingServiceFees,
    openOrders,
    order,
    charities,
    vendors,
    invoicesSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
