import { useState } from 'react';

import { Box } from '@mui/material';
import { Job } from '@features/orders/order/types';
import { useFormContext } from 'react-hook-form';

import Shipment from './Shipment';
import {
  FirstView,
  Content,
} from './styled';
import JobRightSection from './JobRight';
import JobLeftSection from './JobLeft';
import TopSection from './TopSection';
import EmailUpdate from './Email update';
import { IVendorOptions } from '../../utils/types';

interface IMainJobInformation {
  job: Job;
  jobIndex: number;
  paymentMethod: string;
  vendorsList: IVendorOptions[];
}

const MainJobInformation = ({
  job,
  jobIndex,
  paymentMethod,
  vendorsList,
}: IMainJobInformation) => {
  const isEditable = !job?.isEDDM && paymentMethod === 'payment_terms';

  const keyByIndex = `jobs[${jobIndex}]`;
  const [toggledShipments, setToggledShipments] = useState<string[] | []>([]);
  const { shipments } = job;

  return (
    <>
      <Box sx={{ marginTop: "24px" }}>
        <FirstView>
          <TopSection
            job={job}
          />
          <Content>
            <JobLeftSection
              job={job}
              jobIndex={jobIndex}
              isEditable={isEditable}
              keyByIndex={keyByIndex}
            />
            <JobRightSection
              job={job}
              vendorsList={vendorsList}
              keyByIndex={keyByIndex}
            />
          </Content>

          {/* <FirstViewTitles>ITEM</FirstViewTitles>
          <FirstViewTitles>PRICE</FirstViewTitles> */}
          {/* <FirstViewBody>
            {jobNumber}
          </FirstViewBody>
          <AttributesSection job={job} jobIndex={jobIndex} vendorsList={vendorsList} />
          <FirstViewBody padding={0}>
            <PricesSection
              tax={tax}
              price={price}
              total={total}
              shipping={shipping}
            />
            <RadioSection jobIndex={jobIndex} />
            <FirstViewTitles customborder={1}>MAILING SERVICE</FirstViewTitles>
            <MailingServiceFiled>
              <Input
                placeholder="Enter"
                label="Mailing PO:"
                {...register(`${keyByIndex}.mailingPO`)}
                errorMessage={messageJob?.mailingPO?.message}
              />
            </MailingServiceFiled>
            {isMailing ? (
              <MailingFileUploader>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>Mailing List:</Typography>
                  <LinkedString target="_blank" width="120px" href={getCDNImagePath(mailingFile)} text={mailingFile} />
                </Box>
                <FileUploader
                  outOfFormSystem="mailingFile"
                  name={`${keyByIndex}.mailingFile`}
                  errorMessage=""
                  fileExtensions={['.csv', '.xls', '.xlsx']}
                  btnProps={{
                    width: '157px',
                    btnText: "Choose File",
                    isOutlined: true,
                  }}
                />
              </MailingFileUploader>
            ) : null}
          </FirstViewBody> */}
        </FirstView>
      </Box>
      <Shipment
        jobIndex={jobIndex}
        shipmentsList={shipments}
        toggledShipments={toggledShipments}
        setToggledShipments={setToggledShipments}
      />
      <EmailUpdate
        jobIndex={jobIndex}
        keyByIndex={keyByIndex}
      />
    </>
  );
};

export default MainJobInformation;
