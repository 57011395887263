import { forwardRef, memo, ReactNode } from 'react';

import { InputBaseProps } from '@mui/material/InputBase';

import { StyledBaseInput, StyledFormControl, StyledInputBox, StyledInputLabel } from './styled';
import ErrorMessage from '../ErrorMessage';

export interface IBaseInputProps extends InputBaseProps {
  label?: string | ReactNode;
  width?: string;
  errorMessage?: string;
  marginBottom?: number;
  showError?: boolean;
  orderDesignStyle?: boolean;
  onlyBorder?: boolean;
}

const BaseInput = forwardRef(({
  id,
  label,
  inputProps,
  sx,
  width,
  marginBottom,
  errorMessage,
  showError,
  placeholder,
  orderDesignStyle,
  onlyBorder,
  ...restProps
}: IBaseInputProps, ref) => (
  <StyledInputBox
    onlyborder={onlyBorder ? 1 : 0}
    marginBottom={marginBottom}
    errorMessage={!!errorMessage}
    orderdesignstyle={orderDesignStyle ? 1 : 0}
  >
    {label && (
    <StyledInputLabel shrink htmlFor={id}>
      {label}
    </StyledInputLabel>
    )}
    <StyledFormControl
      width={width}
      variant="standard"
      error={!!errorMessage || showError}
    >
      <StyledBaseInput
        inputRef={ref}
        inputProps={inputProps}
        sx={sx}
        {...restProps}
        placeholder={placeholder || (typeof label === 'string' ? label : '')}
      />
    </StyledFormControl>
    {!onlyBorder && !!errorMessage && (
      <ErrorMessage message={errorMessage} />
    )}
  </StyledInputBox>
));

export default memo(BaseInput);
