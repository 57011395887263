import { IRequestCount } from '@features/requests/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData } from '@utils/globalTypes';
import { AxiosResponse } from 'axios';

import { IEstimatesCount } from './types';

export const getRequestsCount = createAsyncThunk<IRequestCount, void, { rejectValue: AxiosData}>(
  'sidebarCounts/requests',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IRequestCount>>('/requests/count');

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getEstimatesCount = createAsyncThunk<IEstimatesCount, void, { rejectValue: AxiosData}>(
  'sidebarCounts/estimates',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<IEstimatesCount>>('/custom-estimates/count');

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
