import { Job } from "@features/orders/order/types";

import { IOrderForm, Shipment } from "./formPayloadType";

export const preparingShipments = (job: Job) => {
  return job.shipments.map((shipment) => {
    const { sets } = shipment;

    const setsList = sets.map((
      {
      id,
      backProof,
      frontProof,
      backArtWork,
      frontArtWork,
      // backProofNotes,
      // frontProofNotes,
    },
  ) => {
      return {
        id,
        backProof,
        frontProof,
        backArtWork,
        frontArtWork,
        // backProofNotes: backProofNotes || '',
        // frontProofNotes: frontProofNotes || '',
      };
    });

    // const shipTo = {
    //   phone: shipment.shipTo?.phone,
    //   city: shipment.shipTo?.city,
    //   company: shipment.shipTo?.company,
    //   country: shipment.shipTo?.country,
    //   address1: shipment.shipTo?.address1,
    //   address2: shipment.shipTo?.address2,
    //   fullName: shipment.shipTo?.fullName,
    //   state: shipment.shipTo?.state,
    //   zipCode: shipment.shipTo?.zipCode,
    // };

    // const dropShip = {
    //   phone: shipment.dropShip?.phone,
    //   city: shipment.dropShip?.city,
    //   company: shipment.dropShip?.company,
    //   country: shipment.dropShip?.country,
    //   address1: shipment.dropShip?.address1,
    //   address2: shipment.dropShip?.address2,
    //   fullName: shipment.dropShip?.fullName,
    //   state: shipment.dropShip?.state,
    //   zipCode: shipment.dropShip?.zipCode,
    // };

    // const newShipment: Shipment = {
    const newShipment = {
      id: shipment?.id,
      trackingNumber: shipment.trackingNumber || '',
      trackingCarrier: shipment.trackingCarrier || '',
      // isMailing: job.isMailing,
      // isDropShip: false,
      sets: setsList,
    };

    // if (shipment?.shipTo) {
    //   newShipment = {
    //     ...newShipment,
    //     shipTo,
    //   } as Shipment;
    // }

    // if (shipment?.dropShip) {
    //   newShipment = {
    //     ...newShipment,
    //     isDropShip: true,
    //     dropShip,
    //   } as Shipment;
    // }

    return newShipment;
  });
};

export const preparingFormData = (jobs: Job[]): IOrderForm[] => {
  const collection = jobs.map((job) => {
    return {
      id: job?.id,
      name: job?.name,
      notes: job?.notes || '',
      status: job?.status,
      mailingPO: job?.mailingPO || '',
      mailingFile: job?.mailingFile,
      postageToVendor: job.postageToVendor,
      customerPostage: job.customerPostage,
      postageFromVendor: job.postageFromVendor,
      shipments: preparingShipments(job),
      shipping: Number(job?.shipping),
      attributes: job?.jobAttributes,
      editablePrice: Number(job?.editablePrice),
      editableShipping: Number(job?.editableShipping),
      editableTax: Number(job?.editableTax),
      editableTotal: Number(job?.editableTotal),
      quantityPrice: Number(job?.quantityPrice),
      sendEmail: job?.sendEmail || false,
      emails: job?.emails || [],
      textTemplate: job?.textTemplate,
      emailTemplateId: job?.emailTemplateId,

      vendor: job?.vendor?.name || '',
      vendorId: job?.vendorId || null,
      vendorJob: job?.vendorJob || '',

      vendor2: job?.vendor2?.name || '',
      vendor2Id: job?.vendor2Id || null,
      vendorJob2: job?.vendorJob2 || '',

      vendor3: job?.vendor3?.name || '',
      vendor3Id: job?.vendor3Id || null,
      vendorJob3: job?.vendorJob3 || '',
    };
  });

  return collection;
};

export const preparingRequestPayload = ({ jobs }: { jobs: IOrderForm[]}) => {
      const jobsList = jobs;

      const newJobs = jobsList.map((job) => {
        if (job.vendorId === '') {
          job.vendorId = null;
        }

        return {
          ...job,
          attributes: job?.attributes.map((item) => ({
            ...item,
            attributePrice: Number(item.attributePrice),
          })),
          editablePrice: Number(job?.editablePrice),
          editableShipping: Number(job?.editableShipping),
          editableTax: Number(job?.editableTax),
          editableTotal: Number(job?.editableTotal),
          quantityPrice: Number(job?.quantityPrice),
          // vendor: job.vendorId,
          // vendor2: job.vendor2Id,
          // vendor3: job.vendor3Id,
        };
      });

     return newJobs;
};

export const dynamicAccess = (obj: any, path: string) => {
  return path
      .replace(/\[(\d+)\]/g, '.$1') // Convert array indices from [0] to .0 for easier access
      .split('.') // Split the path by .
      .reduce((acc, key) => acc && acc[key], obj); // Traverse the object
};
