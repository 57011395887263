import { Navigate } from 'react-router-dom';
import PAGE_ROUTES from '@routes/routingEnum';
import CompletedInvoices from '@containers/Orders/CompletedInvoices';
import OpenInvoices from '@containers/Orders/OpenInvoices';
import OpenEstimates from '@containers/Orders/OpenEstimates';
import CompleteEstimates from '@containers/Orders/CompleteEstimates';
import EmailTemplates from '@containers/Orders/EmailTemplates';
import Orders from '@containers/Orders/Orders';
import Order from '@containers/Orders/Order';
import AddInvoice from '@containers/Orders/InvoiceCreateAndUpdate';
import InvoiceView from '@containers/Orders/InvoiceView';
import ProcurementReport from '@containers/Orders/ProcurementReport';
import SendAFile from '@containers/Orders/SendAFile';
import AddEmailTemplate from '@containers/Orders/EmailTemplates/AddComponent';
import EditEmailTemplate from '@containers/Orders/EmailTemplates/EditComponent';
import ViewCustomEstimate from '@containers/Orders/CustomEstimates/EditComponent';
import ReactivatedEstimates from '@containers/Orders/ReactivatedEstimates';
import ViewEmailLog from '@containers/Orders/CustomEstimates/ViewEmailLog';
import AddTask from '@containers/Administration/Tasks/AddComponent';
import EditTask from '@containers/Administration/Tasks/EditComponent';

const OrdersRoutes = {
  path: PAGE_ROUTES.ORDERS,
  children: [
    { element: <Navigate to={PAGE_ROUTES.OPEN_ORDERS} />, index: true },
    { path: PAGE_ROUTES.ORDER, element: <Order /> },

    { path: PAGE_ROUTES.ORDER_ADD_INVOICE, element: <AddInvoice /> },
    { path: PAGE_ROUTES.ORDER_EDIT_INVOICE, element: <AddInvoice /> },
    { path: PAGE_ROUTES.ORDER_INVOICE_VIEW, element: <InvoiceView /> },
    { path: PAGE_ROUTES.OPEN_ORDERS_ADD_TASK, element: <AddTask /> },
    { path: PAGE_ROUTES.OPEN_ORDERS_EDIT_TASK, element: <EditTask /> },

    { path: PAGE_ROUTES.COMPLETED_ORDER_INVOICES, element: <CompletedInvoices /> },
    { path: PAGE_ROUTES.OPEN_ORDERS_INVOICES, element: <OpenInvoices /> },
    { path: PAGE_ROUTES.VIEW_EMAIL_LOG, element: <ViewEmailLog /> },
    {
      path: PAGE_ROUTES.OPEN_ESTIMATES,
      children: [
        { element: <OpenEstimates />, index: true },
        { path: PAGE_ROUTES.VIEW_OPEN_ESTIMATE, element: <ViewCustomEstimate /> },
      ],
    },
    {
      path: PAGE_ROUTES.COMPLETE_ESTIMATES,
      children: [
        { element: <CompleteEstimates />, index: true },
        { path: PAGE_ROUTES.VIEW_COMPLETE_ESTIMATE, element: <ViewCustomEstimate /> },
      ],
    },
    {
      path: PAGE_ROUTES.REACTIVATED_ESTIMATES,
      children: [
        { element: <ReactivatedEstimates />, index: true },
        { path: PAGE_ROUTES.VIEW_REACTIVATED_ESTIMATE, element: <ViewCustomEstimate /> },
      ],
    },
    {
      path: PAGE_ROUTES.EMAIL_TEMPLATES,
      children: [
        { element: <EmailTemplates />, index: true },
        { path: PAGE_ROUTES.ADD_EMAIL_TEMPLATE, element: <AddEmailTemplate /> },
        { path: PAGE_ROUTES.EDIT_EMAIL_TEMPLATE, element: <EditEmailTemplate /> },
      ],
    },
    { path: PAGE_ROUTES.OPEN_ORDERS, element: <Orders /> },
    { path: PAGE_ROUTES.COMPLETED_ORDERS, element: <Orders /> },
    { path: PAGE_ROUTES.CANCELLED_ORDERS, element: <Orders /> },
    { path: PAGE_ROUTES.PROCUREMENT_REPORT, element: <ProcurementReport /> },
    { path: PAGE_ROUTES.SEND_A_FILE, element: <SendAFile /> },
  ],
};

export default OrdersRoutes;
