export enum ShipmentStatusesEnum {
  UPS = 'UPS',
  FEDEX = "FEDEX",
}

export enum OrderTypesEnum {
  open = 'open',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OrderListUpdateRequestFieldKeys {
  status = 'status',
  vendorId = 'vendorId',
  vendorJob = 'vendorJob',
  mailingPO = 'mailingPO',
  mailPo = 'mailPo',
}

export enum OrderStatusesEnum {
  Canceled = 'Canceled',
  Received = 'Received',
  AwaitingArtwork = 'Awaiting Artwork',
  PreFlightArtwork = 'Pre-Flight Artwork',
  AwaitingProofApproval = 'Awaiting Proof Approval',
  Approved = 'Approved',
  BatchImposition = 'Batch Imposition',
  Plated = 'Plated',
  Printing = 'Printing',
  Finishing = 'Finishing',
  Bindery = 'Bindery',
  MailingServicesAddressing = 'Mailing Services Addressing',
  Shipping = 'Shipping',
  Complete = 'Complete',
  QualityControlReprint = 'Quality Control Reprint',
  ProofRejected = 'Proof Rejected',
  IssueWithYourArt = 'Issue with your art',
  Note = 'Note',
  OneSideApprovedOnly = 'One Side Approved Only',
  ArtReminder = 'Art Reminder',
  StillAwaitingProofApproval = 'Still Awaiting Proof Approval',
  OnHold = 'On Hold',
  NewRegistration = 'New Registration',
  AdjustedProofNotice = 'Adjusted Proof Notice',
  DataNeeded = 'Data Needed',
  BleedRequiredForRigidSigns = 'Bleed Required for Rigid Signs',
  OffsetPrintingBleed = 'Offset Printing Bleed',
  FeedbackComplete = 'Feedback Complete',
  ShippingAddressNeeded = 'Shipping Address Needed',
  ArtEdits = 'Art Edits',
  EDDMIndiciaAdjustment = 'EDDM Indicia Adjustment',
}
