import { OrderStatusesEnum, ShipmentStatusesEnum } from "../order/enums";

export enum OpenOrdersRequestTypeEnum {
  simple = 'simple',
  loadMore = 'loadMore',
}

export interface Ids { orderId: string; jobId: string }

export interface IState {
  isLoading: boolean;
  data: IOrder[] | [];
  actionLoading: boolean;
  loadMoreLoading: boolean;
  errorMessage: string | null;
  activeActionsRows: string[];
  isHiddenLoadMoreButton: boolean;
  type: OpenOrdersRequestTypeEnum;
  openJobsSelectedRows: string[];
  ids: Ids[];
}

export interface IOrder {
  id: string;
  orderId: string;
  jobNumber: string;
  date: string;
  billTo: string;
  hasOpenTasks: boolean;
  cost: number;
  status: OrderStatusesEnum;
  mailPo: string;
  vendorId: string;
  vendorName: string;
  vendorJob: string;
  shipments: Shipment[];
  itemFile: string;
  backArtWork:string;
  frontArtWork: string;
  comment: string;
}

export interface Shipment {
  id: string;
  trackingNumber: number;
  trackingCarrier: ShipmentStatusesEnum;
}
