import { useContext } from 'react';

import Loader from '@containers/common/Loader';
import { Box, Checkbox } from '@mui/material';
import {
  UpdateShipmentPayload,
} from '@features/orders/order/types';
import EmptyState from '@containers/common/EmptyState';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum } from '@features/orders/order/enums';
import { usePrevScroll } from "@utils/prevState";

import { headCells } from '../../tableData';
import OrderItemInList from './OrderItemInList';
import { useOpenOrderListLogic } from './useOpenOrderListLogic';
import { StyledTable, LoadButton, StyleWrapper } from './styled';
import { OrderKindsContext } from '../..';

const OpenOrderList = ({ isSearchTerm }: { isSearchTerm: string }) => {
  const jobsKind = useContext(OrderKindsContext);
  const {
    data: ordersList,
    isLoading,
    vendorsList,
    validMessageState,
    statusOptions,
    loadMoreLoading,
    openJobsSelectedRows,
    isHiddenLoadMoreButton,
    handleAllCheckbox,
    startLoadMoreRequest,
    executeWithDebounceSimpleChanges,
    executeWithDebounceShipmentChanges,
  } = useOpenOrderListLogic();

  if (isLoading) {
    return <Loader />;
  }

  let missingOrdersMessage = 'You don’t have any Orders';

  if (jobsKind === OrderStatusesEnum.Canceled) {
    missingOrdersMessage = 'You don’t have any Cancelled Orders';
  } else if (jobsKind === OrderStatusesEnum.Complete) {
    missingOrdersMessage = 'You don’t have any Completed Orders';
  }

  return (
    <Box>
      {
        isSearchTerm && !ordersList?.length ? (
          <EmptyState text={isSearchTerm ? 'No search results found' : missingOrdersMessage} />
        ) : ordersList?.length
          ? (
            <StyleWrapper>
              <StyledTable
                title=""
                headCells={headCells}
                checkboxVia={(
                  <Checkbox
                    name="all"
                    checked={ordersList.length === openJobsSelectedRows.length}
                    onClick={handleAllCheckbox}
                  />
              )}
              >
                {ordersList.map((job) => {
                  const {
                    id,
                    status,
                    vendorId,
                    mailPo,
                    vendorJob,
                  } = job;

                  const alreadyExistsCollection = {
                    status,
                    vendorId,
                    vendorJob,
                    mailingPO: mailPo,
                  };

                  const beginningDebounceForEachChange = (key: OrderListUpdateRequestFieldKeys, value: string) => {
                    executeWithDebounceSimpleChanges({ id, key, value, alreadyExistsCollection });
                  };

                  const beginningDebounceForShipmentChanges = (args: UpdateShipmentPayload) => {
                    executeWithDebounceShipmentChanges(args);
                  };

                  const eachItemCollection = {
                    vendorsList,
                    statusOptions,
                    validMessageState,
                    beginningDebounceForEachChange,
                    beginningDebounceForShipmentChanges,
                    ...job,
                  };

                  return (
                    <OrderItemInList
                      key={id}
                      {...eachItemCollection}
                    />
                  );
                })}
              </StyledTable>
            </StyleWrapper>

          ) : <EmptyState text={missingOrdersMessage} />
      }

      {
        loadMoreLoading ? <Loader /> : isHiddenLoadMoreButton ? null : (
          <Box sx={{ textAlign: 'center' }}>
            <LoadButton
              onClick={startLoadMoreRequest}
            >
              Load more
            </LoadButton>
          </Box>
        )
      }
    </Box>
  );
};

export default OpenOrderList;
