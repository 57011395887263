import MuiModal from '@mui/material/Modal';
import CloseModal from '@assets/images/close-modal.svg';

import { StyledBox, Wrapper, StyledTypography } from './styled';

const Modal = ({
  title = '',
  open,
  handleClose,
  sx,
  children,
}: any) => {
  return (
     <MuiModal
       open={!!open}
       onClose={handleClose}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >
        <StyledBox
          sx={sx}
        >
          <Wrapper>
            <StyledTypography>{title}</StyledTypography>
            <img src={CloseModal} alt="" onClick={handleClose} />
          </Wrapper>
          {children}
        </StyledBox>
     </MuiModal>
  );
};

export default Modal;
