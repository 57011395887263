import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';
import Textarea from '@containers/common/Textarea';

export const EmailUpdateContainer = styled(Box)(() => ({
  padding: '10px',
  order: `1px solid ${COLORS.grey[500]}`,
  background: '#FFFFDF',
  display: 'grid',
  gridTemplateColumns: '105px 1fr 420px',
  gap: '10px',
  minHeight: '114px',
  border: `1px solid ${COLORS.grey[500]}`,
  borderTop: 'none',
}));

export const Parts = styled(Box)(() => ({
}));

export const StyledTextarea = styled(Textarea)(() => ({
  minWidth: '440px',
  width: '100%',
  minHeight: 'unset !important',
  maxHeight: '500px !important',

  '::placeholder': {
    color: '#788B9A',
  },
}));

export const CheckboxWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  label: {
    marginRight: "8px",
  },
  p: {
    color: '#06152B',
    fontSize: '12px',
  },
}));

export const SpecialParts = styled(Box)(() => ({
  display: 'grid',
  gap: '8px',
  gridTemplateRows: 'min-content min-content min-content',

'.MuiStack-root': {

  '.MuiFormControl-root': {
    height: '26px',

    '.MuiInputBase-root': {
      height: '26px',

      '.MuiSelect-select': {
        padding: '5px 12px',
        span: {
          color: '#788B9A',
        },
        '.MuiInputBase-input': {
          padding: '5.5px 12px',

        },
      },
    },
  },
},
}));

export const Wrapper = styled(Box)<{border: number}>(({ border }) => ({
  position: 'relative',
  gap: '10px',
  background: '#fff',
  border: `1px solid ${border ? 'red' : '#CFD9E0'}`,
  minHeight: '26px',
  padding: "5px 12px",
  fontSize: '12px',
  borderRadius: '4px',

  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',

  svg: {
    cursor: 'pointer',
  },
}));

export const StyledInput = styled('input')(() => ({
  fontFamily: "DM Sans",
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '120%',
  height: '14px',
  display: 'inline',
  width: '100%',
  maxWidth: '100%',
  border: 0,

  '&:focus': {
    outline: 'none',
  },

  '::placeholder': {
    color: '#788B9A',
  },
}));

export const StyledText = styled('p')(() => ({
  display: 'flex',
  gap: '10px',
  background: '#80808057',
  padding: '0px 5px 0px 10px',
  alignItems: 'center',
  borderRadius: '4px',
  wordBreak: 'break-word',

  '::placeholder': {
    color: '#788B9A',
  },
}));
