import { useEffect, useRef, useState } from "react";

import { useAppDispatch } from "@features/app/hooks";
import { IEmailTemplate } from "@features/emailTemplate/types";
import { useFormContext } from "react-hook-form";
import { searchEmailTemplates } from "@features/emailTemplate/actions";
import { EMAIL_REGEXP } from "@utils/regexp";

export const useEmailUpdate = (keyByIndex: string, jobIndex: number) => {
  const { watch, register, setError, setValue, formState: { errors } } = useFormContext();

  // @ts-ignore
  const textErrorMessage = errors?.jobs?.[jobIndex]?.textTemplate?.message;
  // @ts-ignore
  const selectErrorMessage = errors?.jobs?.[jobIndex]?.emailTemplateId?.message;
  // @ts-ignore
  const emailsErrorMessage = errors?.jobs?.[jobIndex]?.emails?.message;

  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');

  const [options, setOptions] = useState<{optionName: string; value: string}[] | []>([]);
  const [templates, setTemplates] = useState<IEmailTemplate[] | null>(null);
  const inputRef = useRef(null);

  // @ts-ignore

  const emails = watch(`${keyByIndex}.emails`);

  const templatesRequest = async () => {
    await dispatch(searchEmailTemplates({}))
      .unwrap()
      .then((templatesResponse) => {
        const opt = templatesResponse.map(({ title, id }) => ({
          value: id,
          optionName: title,
        }));

        setOptions(opt);
        setTemplates(templatesResponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    templatesRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEmail = () => {
    const found = emails.find((e: string) => e === email);

    if (found) {
      setError(`${keyByIndex}.emails`, { message: 'The email already exists.' });

      return;
    }

    emailsErrorMessage && setError(`${keyByIndex}.emails`, { message: '' });
    setValue(`${keyByIndex}.emails`, [...emails, email]);
    setEmail('');
    // @ts-ignore
    inputRef?.current?.focus();
  };

  const handleDelete = (index: number) => {
    const newData = [...emails];

    newData.splice(index, 1);

    setValue(`${keyByIndex}.emails`, newData);
  };

  const handleKeyDown = (e: any) => {
    setTimeout(() => {
      const isActualEmail = EMAIL_REGEXP.test(email);

      if (
        e.keyCode === 32 && isActualEmail || e.keyCode === 13 && isActualEmail
      ) {
        handleAddEmail();
      }
    }, 100);
  };

  return {
    textErrorMessage,
    selectErrorMessage,
    emailsErrorMessage,
    email,
    errors,
    inputRef,
    emails,
    setEmail,
    options,
    templates,
    register,
    handleDelete,
    handleKeyDown,
  };
};
