import { useState } from "react";

import Button from "@containers/common/Button";

import { ConversationModal } from "./components";

const Conversation = () => {
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false);

  return (
    <div>
      <Button
        isOutlined
        onClick={() => {
          setIsConversationOpen(true);
        }}
      >
        Open Conversation
      </Button>
      <ConversationModal
        isConversationOpen={isConversationOpen}
        setIsConversationOpen={setIsConversationOpen}
      />
    </div>
  );
};

export default Conversation;
