import { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { GlobalQueryString } from '@utils/globalTypes';
import { useDebounce } from '@features/hooks/useDebounce';
import { getOrderStatusesOptions } from '@utils/orderStatuses';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectOpenOrders } from '@features/orders/openOrders/selectors';
import { OpenOrdersRequestTypeEnum } from '@features/orders/openOrders/types';
import { HandleUpdateRequestProps, UpdateShipmentPayload } from '@features/orders/order/types';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum, ShipmentStatusesEnum } from '@features/orders/order/enums';
import { selectRowsArray, setRequestType, updateRowData } from '@features/orders/openOrders/slice';
import {
  getOrdersThunk,
  updateOrderInfo,
  updateShipmentInfo,
} from '@features/orders/openOrders/actions';
import { getVendorsListThunk } from '@features/vendors/actions';

import { OrderKindsContext } from '../..';
import { isItOpenJobs } from '../../helper';
import { LOAD_MORE_LIMIT } from '../../constants';

export interface ValidMessageState {
  rowId: string;
  message: string;
  where: OrderListUpdateRequestFieldKeys;
}

export interface IVendorOptions {optionName: string; value: string}

export const useOpenOrderListLogic = () => {
  const dispatch = useAppDispatch();

  const { search, pathname } = useLocation();
  const jobsKind = useContext(OrderKindsContext);
  const [offsetCount, setOffsetCount] = useState(0);
  const [vendorsList, setVendorsList] = useState<IVendorOptions[]>([] as IVendorOptions[]);

  const [validMessageState, setValidMessageState] = useState<ValidMessageState>({}as ValidMessageState);
  const params = queryString.parse(search) as GlobalQueryString;

  const {
    data,
    type,
    isLoading,
    loadMoreLoading,
    openJobsSelectedRows,
    isHiddenLoadMoreButton,
  } = useAppSelector(selectOpenOrders);

  const handleInitialRequest = async () => {
    setOffsetCount(0);

    try {
      await dispatch(setRequestType(OpenOrdersRequestTypeEnum.simple));
      await dispatch(getOrdersThunk({
        ...params,
        offset: 0,
        limit: 8,
        status: !jobsKind ? params.status : jobsKind,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleLoadMoreRequest = async () => {
    try {
      let fixedStatus = {};

      if (PAGE_ROUTES.COMPLETED_ORDERS === pathname) {
        fixedStatus = { status: OrderStatusesEnum.Complete };
      } else if (PAGE_ROUTES.CANCELLED_ORDERS === pathname) {
        fixedStatus = { status: OrderStatusesEnum.Canceled };
      }

      await dispatch(getOrdersThunk({
        ...params,
        limit: LOAD_MORE_LIMIT,
        offset: offsetCount * LOAD_MORE_LIMIT,
        ...fixedStatus,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getVendors = async () => {
    try {
      const vendors = await dispatch(getVendorsListThunk()).unwrap();

      const options = vendors.map((vendor) => ({
        optionName: vendor.name,
        value: vendor.id,
      }));

      setVendorsList(options);
    } catch (err) {
      console.log(err);
    }
  };

  const startLoadMoreRequest = async () => {
    await dispatch(setRequestType(OpenOrdersRequestTypeEnum.loadMore));
    await setOffsetCount((prev) => prev + 1);
  };

  const handleUpdateOrder = async ({ id, value = '', key, alreadyExistsCollection }: HandleUpdateRequestProps) => {
    if (OrderListUpdateRequestFieldKeys.vendorJob === key) {
      // move from here
      if (value.length > 255) {
        setValidMessageState({
          rowId: id,
          message: 'The maximum length allowed is 255 characters',
          where: OrderListUpdateRequestFieldKeys.vendorJob,
        });

        return;
      }

      let statusObj = {};

      const isNotOpenJobs = isItOpenJobs(jobsKind);

      // if select status worked from search section '!params.status'
      if (!params.status && isNotOpenJobs) {
        statusObj = { status: jobsKind };
      }

      setValidMessageState(statusObj as ValidMessageState);
    }

    // @ts-ignore
    alreadyExistsCollection[key] = value;

    try {
      const newUpdatedData = data.map((item) => {
        if (
          key === 'vendorId' &&
          item.id === id &&
          value === 'Select'
        ) {
          return { ...item, [key]: null };
        }

        // for update uses 'mailingPO' but get by mailPo
        if (key === 'mailingPO' && item.id === id) {
          return { ...item, mailPo: value };
        }

        if (item.id === id) {
          return { ...item, [key]: value };
        }

        return item;
      });

      if (value === 'Select' && key === OrderListUpdateRequestFieldKeys.vendorId) {
        alreadyExistsCollection.vendorId = null;
      }

      await dispatch(updateRowData(newUpdatedData));
      await dispatch(updateOrderInfo({ id, payload: alreadyExistsCollection })).unwrap();

      if (key === 'vendorId' && typeof value === 'string') {
        handleInitialRequest();
      } else if (key === 'status' && typeof value === 'string') {
        if (pathname !== PAGE_ROUTES.OPEN_ORDERS) {
          handleInitialRequest();

          return;
        }

        // is PAGE_ROUTES.OPEN_ORDERS route
        const fixedStatuses = [
          OrderStatusesEnum.Canceled,
          OrderStatusesEnum.Complete,
        ];

        if (fixedStatuses.includes(value as OrderStatusesEnum)) {
          handleInitialRequest();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error: ', error);
    }
  };

  const handleUpdateOrderShipments = async (payload: UpdateShipmentPayload) => {
    try {
      // in one endpoint backend require uppercase fedex and give with lowercase in another one
      const updatedPayload = {
        ...payload,
        trackingCarrier: payload?.trackingCarrier?.toUpperCase() as ShipmentStatusesEnum,
      };

      dispatch(updateShipmentInfo(updatedPayload)).unwrap();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleAllCheckbox = () => {
    if (openJobsSelectedRows.length === data.length) {
      dispatch(selectRowsArray([]));

      return;
    }

    const ids = data.map((item) => item.id);

    dispatch(selectRowsArray(ids));
  };

  const statusOptions = getOrderStatusesOptions();

  const executeWithDebounceSimpleChanges = useDebounce(handleUpdateOrder, 300);
  const executeWithDebounceShipmentChanges = useDebounce(handleUpdateOrderShipments, 300);

  useEffect(() => {
    handleInitialRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, jobsKind]);

  useEffect(() => {
    if (type === OpenOrdersRequestTypeEnum.loadMore) {
      handleLoadMoreRequest();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetCount]);

  useEffect(() => {
    getVendors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data,
    isLoading,
    vendorsList,
    statusOptions,
    loadMoreLoading,
    validMessageState,
    openJobsSelectedRows,
    isHiddenLoadMoreButton,
    handleAllCheckbox,
    startLoadMoreRequest,
    executeWithDebounceSimpleChanges,
    executeWithDebounceShipmentChanges,
  };
};
