import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const SetsContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
}));

export const BoxInformation = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
}));

export const StyledBox = styled(Box)(() => ({
  borderTop: `none`,
  borderLeft: `1px solid ${COLORS.grey[500]}`,

  '&:first-of-type': {
    borderLeft: 'none',
  },
}));

export const Title = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px',
  borderBottom: `1px solid ${COLORS.grey[500]}`,
  background: COLORS.blue.light,
}));

export const SetNameWrapper = styled(Box)(() => ({
  padding: '7px 16px',
  fontSize: '12px',
  fontWeight: 700,
  borderBottom: `1px solid ${COLORS.grey[500]}`,
  background: COLORS.blue.light,

  p: {
    color: '#788B9A',
    fontFamily: "DM Sans",
    fontsize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '120%',
  },

}));

export const Content = styled(Box)(() => ({
  display: 'grid',
  gap: '8px',
  padding: '17px 16px',
  gridTemplateColumns: '1fr',
}));

export const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  gap: '10px',
  marginBottom: 9,
  justifyContent: 'flex-start',
}));

export const StyledUnderlined = styled('span')(() => ({
  cursor: 'pointer',
  color: COLORS.blue.dark,
  textDecoration: 'underline',
}));

export const DeleteButton = styled('span')(() => ({
  cursor: 'pointer',
}));

export const MissingMessage = styled(Typography)(() => ({
  textAlign: 'center',
}));

export const Colored = styled('span')(({ status }: { status: string }) => ({
  color: status === 'accepted' ? 'green' : 'red',
}));
