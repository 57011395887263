import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@customTypes/global/theme/colors';

export const Content = styled(Box)(() => ({
  padding: '17px 16px',
  display: 'grid',
  height: '1px',
  gap: '10px',
  gridTemplateRows: '1fr 1fr',
}));

export const ArtWorkWrapper = styled(Box)(() => ({
  display: 'grid',
  gap: '10px',
  padding: '10px',
  gridTemplateColumns: '1fr 20px',
  alignItems: 'center',
  border: `1px solid #F1F4FA`,
}));

export const StyledUnderlined = styled('span')(() => ({
  cursor: 'pointer',
  color: COLORS.blue.dark,
  lineHeight: 'normal',
  textDecoration: 'underline',
}));

export const TitleText = styled(Typography)(() => ({
  color: '#788B9A',
  fontSize: '12px',
  fontWeight: 400,
}));

export const ImageWrapper = styled(Typography)(() => ({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
}));
