import { useFormContext } from 'react-hook-form';
import { Job } from '@features/orders/order/types';
import { orderingArrayByArray } from '@utils/attributesArrayOrders';
import Input from '@containers/common/Input';
import { Box } from '@mui/material';

import {
  FirstViewBodyException,
  StyledWrapperDynamically,
} from './styled';

interface EditableAttributesProps {
  job: Job;
  jobIndex: number;
}

const EditableAttributes = ({ job, jobIndex }: EditableAttributesProps) => {
  const CUSTOM_ESTIMATE_KEY = 'custom estimate';
  const { register, formState: { errors } } = useFormContext();
  const keyByIndex = `jobs[${jobIndex}]`;

  // const messagesObjJobs = errors?.jobs as Record<string, any>;
  // let messageJob = {} as Record<string, any>;
  // if (messagesObjJobs && messagesObjJobs[jobIndex]) {
  //   messageJob = messagesObjJobs[jobIndex];
  // }

  let attributes = { ...job?.item } as Record<string, any>;

  delete attributes.item;

  if (job?.customEstimateId) {
    attributes = {
      ...attributes,
      [CUSTOM_ESTIMATE_KEY]: job.customEstimateId,
    };
  }

  if (job?.widthGP && job?.heightGP && job?.quantityGP) {
    const { quantity, ...rest } = attributes;

    attributes = {
      ...rest,
      width: job.widthGP,
      height: job.heightGP,
      quantity: job.quantityGP,
    };
  }

  const { jobAttributes } = job;

  const attributesList = orderingArrayByArray(jobAttributes, ['Ink']);

  return (
    <FirstViewBodyException>
      {
        attributesList.map((attribute, index) => {
          // @ts-ignore
          const priceMessage = errors?.jobs?.[jobIndex]?.attributes?.[index]?.attributePrice?.message;

          return (
            <StyledWrapperDynamically key={attribute.id}>
              <label>
                {attribute.attributeCategoryName}
                :
              </label>
              <Input
                orderDesignStyle
                {...register(`${keyByIndex}.attributes[${index}].attributeName`)}
                label=""
                placeholder="Enter"
              />
              <Box
                sx={{ gap: 1 }}
              >
                <p>$</p>
                 <Input
                   orderDesignStyle
                   {...register(`${keyByIndex}.attributes[${index}].attributePrice`)}
                   label=""
                   placeholder="Enter"
                   onlyBorder
                   errorMessage={priceMessage}
                 />
              </Box>
            </StyledWrapperDynamically>
          );
        })
      }
      <StyledWrapperDynamically>
        <label> Shipping Price: </label>
        <div />
        <Box
          sx={{ gap: 1 }}
        >
          <p>$</p>
            <Input
              orderDesignStyle
              {...register(`${keyByIndex}.editableShipping`)}
              label=""
              placeholder="Enter"
            />
        </Box>
      </StyledWrapperDynamically>
    </FirstViewBodyException>
  );
};

export default EditableAttributes;
