import { useState } from "react";

import Button from "@containers/common/Button";
import { Typography } from "@mui/material";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import circleArrowLeft from "@assets/images/circleArrowLeft.svg";
import circleArrowRight from "@assets/images/circleArrowRight.svg";
import { buildRoute } from "@routes/helpers";
import { selectOrderDetails } from "@features/orders/order/selectors";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectOrdersIdList } from "@features/orders/openOrders/selectors";
import { ORDERS_IDS } from "@utils/constants";
import StorageManager from "@utils/storage-manager";
import { Ids } from "@features/orders/openOrders/types";
import { getCDNImagePath } from "@utils/helpers";
import { generateOrderPDF } from "@features/orders/order/actions";

import { Navbar, TopButtons, TopSection, BottomButtonsWrapper } from "./styled";

const TopNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pdfLoading, setLoading] = useState(false);
  const { orderId = "", jobId = "" } = useParams();
  const ids = useAppSelector(selectOrdersIdList);
  const {
    order: { orderNumber, archiveZip },
  } = useAppSelector(selectOrderDetails);

  const idsList = ids.length
    ? ids
    : (StorageManager.getItem(ORDERS_IDS) as Ids[]);

  const navigateAnotherOrder = (direction: "left" | "right") => {
    const index = idsList.findIndex((i) => i.jobId === jobId);

    const { length } = idsList;

    if (
      (index === 0 && direction === "left") ||
      (length - 1 === index && direction === "right")
    ) {
      return;
    }

    const toDo = direction === "left" ? index - 1 : index + 1;
    const url = buildRoute(PAGE_ROUTES.ORDER, {
      orderId: idsList[toDo].orderId,
      jobId: idsList[toDo].jobId,
    });

    navigate(url);
  };

  const handleGeneratePDF = async () => {
    setLoading(true);

    try {
      const response = await dispatch(generateOrderPDF({ id: orderId })).unwrap();

      openInNewTab(getCDNImagePath(response));
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  if (!orderId || orderId === "undefined") {
    return <Navigate to={PAGE_ROUTES.OPEN_ORDERS} />;
  }

  return (
    <Navbar>
      <TopSection>
        <Typography variant="h1" component="h1">
          Order ID:
          {' '}
          {orderNumber}
        </Typography>
        {idsList.length ? (
          <TopButtons>
            <img
              src={circleArrowLeft}
              alt="Circle Arrow Left"
              onClick={() => navigateAnotherOrder("left")}
            />
            <img
              src={circleArrowRight}
              alt="Circle Arrow Right"
              onClick={() => navigateAnotherOrder("right")}
            />
          </TopButtons>
        ) : null}
        <BottomButtonsWrapper>
          <Button
            width={96}
            onClick={() => {
              const url = buildRoute(PAGE_ROUTES.OPEN_ORDERS_ADD_TASK, {
                orderId,
                jobId,
              });

              navigate(url);
            }}
          >
            Add Task
          </Button>
          <Button
            width={96}
            onClick={() => {
              const url = buildRoute(PAGE_ROUTES.ORDER_ADD_INVOICE, {
                orderId,
                jobId,
              });

              navigate(url);
            }}
          >
            Add Invoice
          </Button>

          <Button
            width={154}
            isOutlined
            disabled={pdfLoading}
            onClick={() => {
              handleGeneratePDF();
            }}
          >
            {pdfLoading ? "Loading" : 'View PDF Receipt'}
          </Button>
          <Link to={archiveZip} download>
            <Button
              width={154}
              isOutlined
              disabled={pdfLoading}
            >
              Download All Artworks
            </Button>
          </Link>
        </BottomButtonsWrapper>
      </TopSection>
    </Navbar>
  );
};

export default TopNavbar;
