import { EMAIL_REGEXP } from '@utils/regexp';
import * as yup from 'yup';

export interface ISendEmailForm {
  emails: string[];
  notes: string;
  template: string;
  buttonLink: string;
  buttonText: string;
  textTemplate: string;
}

export const SendEmailFormValidation = yup.object().shape({
  emails: yup.array()
    .of(
      yup
        .string()
        .nullable()
        .matches(EMAIL_REGEXP, 'Email must be a valid email'),
    ),
  notes: yup.string().optional(),
  template: yup.string().optional(),
  textTemplate: yup.string().optional(),
});
