/* eslint-disable max-len */
import { AxiosResponse } from "axios";
import { http } from "@services/RequestService";
import { AxiosData } from "@utils/globalTypes";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { customErrorHandling } from "@utils/errorHandler";
import {
  IAddInvoicePayload,
  IUpdateInvoicePayload,
} from "@containers/Orders/InvoiceCreateAndUpdate/type";
import {
  IOrderTask,
  UpdateSetPayload,
} from "@containers/Orders/Order/components/Switcher/components/BasicInfo/types";
import { IOrderUpdatePayload } from "@containers/Orders/Order/components/Switcher/components/BasicInfo/OrderDetails/utils/formPayloadType";
import { IInvoice } from "@features/invoices/types";

import { IOrder } from "./types";

const prefix = "/orders";

export const getOrderDetails = createAsyncThunk<
  IOrder,
  { id: string },
  { rejectValue: AxiosData }
>("order/single", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IOrder>>(`${prefix}/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getShippingDataByOrderDetails = createAsyncThunk<
  IOrder,
  { id: string },
  { rejectValue: AxiosData }
>("shipping-get-by-order/single", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IOrder>>(`${prefix}/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderInfoDetails = createAsyncThunk<
  void,
  IOrderUpdatePayload,
  { rejectValue: AxiosData }
>("order/update", async ({ id, payload }, thunkAPI) => {
  try {
    await http.put(`${prefix}/${id}/details`, { jobs: payload });
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderAutoEmail = createAsyncThunk<
  void,
  { orderId: string; autoEmail: boolean },
  { rejectValue: AxiosData }
>(`order/autoEmail`, async ({ orderId, autoEmail }, thunkAPI) => {
  try {
    await http.put(`${prefix}/${orderId}/autoEmail`, { autoEmail });
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getOrderTasks = createAsyncThunk<
  IOrderTask[],
  { id: string },
  { rejectValue: AxiosData }
>("tasks/order", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IOrderTask[]>>(`/tasks/order/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateJobSetInfo = createAsyncThunk<
  void,
  { id: string; payload: Partial<UpdateSetPayload> },
  { rejectValue: AxiosData }
>("order-job/set-data", async ({ id, payload }, thunkAPI) => {
  try {
    await http.patch<Partial<UpdateSetPayload>>(
      `${prefix}/sets/${id}`,
      payload,
    );
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateJobMailingFile = createAsyncThunk<
  void,
  { id: string; mailingFile: string },
  { rejectValue: AxiosData }
>("order-job/mailing-file", async ({ id, mailingFile }, thunkAPI) => {
  try {
    await http.patch<{ mailingFile: string }>(`${prefix}/jobs/${id}`, {
      mailingFile,
    });
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

const prefixInvoice = "/invoices";

export const addOrderInvoice = createAsyncThunk<
  void,
  IAddInvoicePayload,
  { rejectValue: AxiosData }
>("orderInvoice/add", async (body, thunkAPI) => {
  try {
    await http.post<IAddInvoicePayload>(prefixInvoice, body);
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderInvoice = createAsyncThunk<
  void,
  IUpdateInvoicePayload,
  { rejectValue: AxiosData }
>("orderInvoice/update", async (body, thunkAPI) => {
  try {
    await http.put<IUpdateInvoicePayload>(
      `${prefixInvoice}/${body.invoiceId}`,
      body,
    );
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getOrderInvoices = createAsyncThunk<
  { invoices: IInvoice[]; billingAddress: Record<string, any> },
  { id: string },
  { rejectValue: AxiosData }
>("orderInvoice/get", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<
      AxiosResponse<{
        invoices: IInvoice[];
        billingAddress: Record<string, any>;
      }>
    >(`/invoices/order/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getInvoiceById = createAsyncThunk<
  IInvoice,
  { id: string },
  { rejectValue: AxiosData }
>("orderInvoice/get", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IInvoice>>(`${prefixInvoice}/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const getOrderDetailsForInvoice = createAsyncThunk<
  IOrder,
  { id: string },
  { rejectValue: AxiosData }
>("order-for-invoice/single", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.get<AxiosResponse<IOrder>>(`${prefix}/${id}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const generateOrderPDF = createAsyncThunk<
  any,
  { id: string },
  { rejectValue: AxiosData }
>("generate-order-pdf/single", async ({ id }, thunkAPI) => {
  try {
    const {
      data: { data },
    } = await http.post<AxiosResponse<any>>(`${prefix}/${id}/pdf`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});
