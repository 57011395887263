import { createSlice } from "@reduxjs/toolkit";
import { LOAD_MORE_LIMIT } from "@containers/Orders/Orders/constants";
import { setOrdersIdsData } from "@features/auth/helpers";

import { IState, OpenOrdersRequestTypeEnum } from "./types";
import { getOrdersThunk } from "./actions";

const initialState: IState = {
  data: [],
  ids: [],
  isLoading: true,
  errorMessage: null,
  actionLoading: true,
  loadMoreLoading: false,
  activeActionsRows: [],
  openJobsSelectedRows: [],
  isHiddenLoadMoreButton: false,
  type: OpenOrdersRequestTypeEnum.simple,
};

const openOrdersSlice = createSlice({
  name: "openOrdersSlice",
  initialState,
  reducers: {
    selectRow: (state, { payload }) => {
      if (state.openJobsSelectedRows.includes(payload)) {
        state.openJobsSelectedRows = [
          ...state.openJobsSelectedRows.filter((item) => item !== payload),
        ];
      } else {
        state.openJobsSelectedRows.push(payload);
      }
    },
    selectRowsArray: (state, { payload }) => {
      state.openJobsSelectedRows = payload;
    },
    setRequestType: (state, { payload }) => {
      state.type = payload;
    },
    updateRowData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrdersThunk.pending, (state) => {
      if (state.type === OpenOrdersRequestTypeEnum.simple) {
        state.isLoading = true;

        return;
      }

      state.loadMoreLoading = true;
    });
    builder.addCase(getOrdersThunk.fulfilled, (state, { payload }) => {
      const ids = payload.map((job) => ({ orderId: job.orderId, jobId: job.id }));

      state.ids = ids;
      setOrdersIdsData(ids);

      if (state.type === OpenOrdersRequestTypeEnum.simple) {
        state.data = payload;
        state.isLoading = false;
        state.isHiddenLoadMoreButton = false;

        if (payload.length < LOAD_MORE_LIMIT) {
          state.isHiddenLoadMoreButton = true;
        }

        return;
      }

      state.data = [...state.data, ...payload];
      state.loadMoreLoading = false;

      if (payload.length < LOAD_MORE_LIMIT) {
        state.isHiddenLoadMoreButton = true;
      }
    });
    builder.addCase(getOrdersThunk.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
      state.loadMoreLoading = false;
    });
  },
});

export const { selectRow, selectRowsArray, setRequestType, updateRowData } = openOrdersSlice.actions;

export default openOrdersSlice.reducer;
