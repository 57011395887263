import { Job } from "@features/orders/order/types";
import { priceFormatting } from "@utils/helpers";

import { GrayBackground, Wrapper, Text } from './styled';

const AttributePrices = ({ job, isEditable }: {job: Job; isEditable: boolean }) => {
  return (
    <GrayBackground>
        <Wrapper>
          <Text>Subtotal:</Text>
          <p>{priceFormatting(isEditable ? job.editablePrice : job.price)}</p>
        </Wrapper>
        <Wrapper>
          <Text>Tax:</Text>
          <p>{priceFormatting(isEditable ? job.editableTax : job.tax)}</p>
        </Wrapper>
        <Wrapper>
          <Text>Total:</Text>
          <p>{priceFormatting(isEditable ? job.editableTotal : job.total)}</p>
        </Wrapper>
    </GrayBackground>
  );
};

export default AttributePrices;
