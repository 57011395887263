import { createSlice } from '@reduxjs/toolkit';
import PAGE_ROUTES from '@routes/routingEnum';

import { IState } from './types';
import { getEstimatesCount, getRequestsCount } from './actions';

const initialState: IState = {
  isLoading: true,
  [PAGE_ROUTES.REQUESTS]: null,
  [PAGE_ROUTES.OPEN_ESTIMATES]: null,
  [PAGE_ROUTES.COMPLETE_ESTIMATES]: null,
  [PAGE_ROUTES.REACTIVATED_ESTIMATES]: null,
};

const sidebarCountsSlice = createSlice({
  name: 'sidebarCounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRequestsCount.pending, (state) => {});
    builder.addCase(getRequestsCount.fulfilled, (state, { payload }) => {
      const { nonProfit, reseller } = payload;

      state[PAGE_ROUTES.REQUESTS] = nonProfit + reseller;
      state.isLoading = false;
    });
    builder.addCase(getRequestsCount.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getEstimatesCount.pending, (state) => {});
    builder.addCase(getEstimatesCount.fulfilled, (state, { payload }) => {
      const { openEstimates, completeEstimates, reactivatedEstimates } = payload;

      state[PAGE_ROUTES.OPEN_ESTIMATES] = openEstimates;
      state[PAGE_ROUTES.COMPLETE_ESTIMATES] = completeEstimates;
      state[PAGE_ROUTES.REACTIVATED_ESTIMATES] = reactivatedEstimates;
      state.isLoading = false;
    });
    builder.addCase(getEstimatesCount.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default sidebarCountsSlice.reducer;
