import { memo } from 'react';

import Select from '@containers/common/Select';
import Checkbox from '@containers/common/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import ErrorMessage from '@containers/common/ErrorMessage';

import { useEmailUpdate } from './useEmailUpdate';
import {
  Wrapper,
  Parts,
  StyledInput,
  SpecialParts,
  StyledTextarea,
  CheckboxWrapper,
  EmailUpdateContainer,
  StyledText,
} from './styled';

interface EmailUpdateProps {
  keyByIndex: string;
  jobIndex: number;
}

const EmailUpdate = ({ keyByIndex, jobIndex }: EmailUpdateProps) => {
  const {
    textErrorMessage,
    selectErrorMessage,
    emailsErrorMessage,
    email,
    inputRef,
    emails,
    setEmail,
    options,
    register,
    handleDelete,
    handleKeyDown,
  } = useEmailUpdate(keyByIndex, jobIndex);

  return (
    <EmailUpdateContainer>
      <Parts>
        Email Update
      </Parts>
      <Parts>
        <StyledTextarea
          vtResize
          placeholder="Email text"
          {...register(`${keyByIndex}.textTemplate`)}
          errorMessage={textErrorMessage}
        />
      </Parts>
      <SpecialParts>
        <Select
          onlyBorder
          placeholderText="Select email template"
          name={`${keyByIndex}.emailTemplateId`}
          options={options}
          errorMessage={selectErrorMessage}
        />
        <div>
          <Wrapper
            border={emailsErrorMessage ? 1 : 0}
            onClick={() => {
              // @ts-ignore
              inputRef?.current?.focus();
            }}
          >
            {
              emails?.map((e: string, index: number) => (
                <StyledText
                  key={e}
                >
                  {e}
                  <CloseIcon
                    onClick={() => handleDelete(index)}
                  />
                </StyledText>
              ))
            }
           <StyledInput
             ref={inputRef}
             type="text"
             value={email}
             placeholder="Additional emails"
             onChange={(e) => {
              setEmail(e.target.value);
             }}
             onKeyDown={handleKeyDown}
           />
          </Wrapper>
        {emailsErrorMessage && <ErrorMessage message={emailsErrorMessage} />}
        </div>
         <CheckboxWrapper>
            <Checkbox
              name={`${keyByIndex}.sendEmail`}
            />
            <p>Send an email update to customer after submitting</p>
         </CheckboxWrapper>
      </SpecialParts>
    </EmailUpdateContainer>
  );
};

export default memo(EmailUpdate);
