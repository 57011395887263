import { createSlice } from "@reduxjs/toolkit";
import { LOAD_MORE_LIMIT } from "@containers/Orders/Invoices/constants";

import { IState } from "./types";
import { getInvoicesThunk } from "./actions";

const initialState: IState = {
  data: [],
  isLoading: true,
  errorMessage: null,
  actionLoading: true,
  loadMoreLoading: false,
  openJobsSelectedRows: [],
  isHiddenLoadMoreButton: false,
};

const invoicesSlice = createSlice({
  name: "invoicesSlice",
  initialState,
  reducers: {
    clearInvoices: (state) => {
      state.data = [];
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoicesThunk.pending, (state) => {});
    builder.addCase(getInvoicesThunk.fulfilled, (state, { payload }) => {
      state.data = [...state.data, ...payload];
      state.loadMoreLoading = false;
      state.isLoading = false;

      if (payload.length < LOAD_MORE_LIMIT) {
        state.isHiddenLoadMoreButton = true;
      } else {
        state.isHiddenLoadMoreButton = false;
      }
    });
    builder.addCase(getInvoicesThunk.rejected, (state) => {
      state.data = [];
      state.isLoading = false;
    });
  },
});

export const { clearInvoices } = invoicesSlice.actions;

export default invoicesSlice.reducer;
