/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';

import Button from '@containers/common/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/material';
import Input from '@containers/common/Input';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '@containers/common/Select';
import Textarea from '@containers/common/Textarea';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { searchEmailTemplates, sendEmailTemplate } from '@features/emailTemplate/actions';
import Loader from '@containers/common/Loader';
import { IEmailTemplate, ISendEmailTemplatePayload } from '@features/emailTemplate/types';
import { EMAIL_REGEXP } from '@utils/regexp';
import CloseIcon from '@mui/icons-material/Close';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import ErrorMessage from '@containers/common/ErrorMessage';
import Typography from '@mui/material/Typography';
import { selectOrderDetails } from '@features/orders/order/selectors';
import { getOrderDetails } from '@features/orders/order/actions';
import { buildRoute } from '@routes/helpers';
import PAGE_ROUTES from '@routes/routingEnum';

import { ISendEmailForm, SendEmailFormValidation } from './helpers';
import { StyledTemplateWrapper, Wrapper, StyledInput, StyledText, StyledLink } from './styled';

const SendEmailDialog = ({ handleClose }: { handleClose: () => void}) => {
  const { orderId } = useParams();
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();

  const { order } = useAppSelector(selectOrderDetails);

  const [email, setEmail] = useState('');
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const [templates, setTemplates] = useState<IEmailTemplate[] | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate | null>(null);
  const [options, setOptions] = useState<{optionName: string; value: string}[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const methods = useForm<ISendEmailForm>({
    // @ts-ignore
    resolver: yupResolver(SendEmailFormValidation),
    defaultValues: {
      emails: [],
      template: '',
      textTemplate: '',
    },
  });

  const { handleSubmit, register, watch, setValue, setError, formState: { errors } } = methods;

  const emails = watch('emails');
  const template = watch('template');
  const buttonLink = watch('buttonLink');
  const buttonText = watch('buttonText');

  const onSubmit = async (data: ISendEmailForm) => {
    if (!selectedTemplate?.id || !orderId) {
      return;
    }

    try {
      const payload = {
        orderId,
        id: selectedTemplate?.id,
      } as ISendEmailTemplatePayload;

      if (data?.emails?.length) {
        payload.emails = emails;
      }

      await dispatch(sendEmailTemplate(payload)).unwrap();

      handleClose();
    } catch (error: any) {
      if (typeof error.message === 'string') {
        setIsSuccessMsg({ msg: error.message, status: 400 });
      } else {
        setIsSuccessMsg({ msg: 'Something went wrong.', status: 400 });
      }
    }
  };

  const handleAddEmail = () => {
    const found = emails.find((e) => e === email);

    if (found) {
      setError('emails', { message: 'The email already exists.' });

      return;
    }

    errors?.emails?.message && setError('emails', { message: '' });
    setValue('emails', [...emails, email]);
    setEmail('');
    // @ts-ignore
    inputRef?.current?.focus();
  };

  const handleKeyDown = (e: any) => {
    setTimeout(() => {
      const isActualEmail = EMAIL_REGEXP.test(email);

      if (
        e.keyCode === 32 && isActualEmail || e.keyCode === 13 && isActualEmail
      ) {
        handleAddEmail();
      }
    }, 100);
  };

  const handleDelete = (index: number) => {
    const newData = [...emails];

    newData.splice(index, 1);

    setValue('emails', newData);
  };

  const updateVewDataByTemplate = () => {
    if (!templates?.length || !template) {
      setSelectedTemplate(null);
      setValue('buttonLink', '');
      setValue('buttonText', '');
      setValue('textTemplate', '');

      return;
    }

    const found = templates?.find((item) => item.id === template);

    if (!found) {
      alert('not found');

      return;
    }

    setSelectedTemplate(found || null);
    setValue('buttonLink', found?.buttonLink);
    setValue('buttonText', found?.buttonText);
    setValue('textTemplate', found?.textTemplate);
  };

  const getTemplatesList = async () => {
    try {
      if (orderId) {
        dispatch(getOrderDetails({ id: orderId })).unwrap();
      }

      await dispatch(searchEmailTemplates({}))
        .unwrap()
        .then((templatesResponse) => {
          const opt = templatesResponse.map(({ title, id }) => ({
            value: id,
            optionName: title,
          }));

          setOptions(opt);
          setTemplates(templatesResponse);
          setIsLoading(false);
        });
      // .catch(() => setIsLoading(false));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTemplatesList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateVewDataByTemplate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const customerUrl = buildRoute(PAGE_ROUTES.EDIT_CUSTOMER, { id: order?.customer?.id });

  return (
    <FormProvider {...methods}>
      <Stack
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        gap="16px"
      >
        {
          isLoading
            ? (
              <Loader sx={{ minHeight: "100px" }} />
            )
            : (
              <>
                <Typography variant="h4">Send new email</Typography>
                <Typography variant="body3">
                  <StyledLink to={customerUrl}>
                    {order?.customer?.email || ""}
                  </StyledLink>
                  {' '}
                  will receive order update alerts. You can add additional
                  email addresses below to receive updates. Type an email in field below and press Enter or Space to add it to the list.
                </Typography>
                <Box>
                  <Wrapper
                    border={errors?.emails?.message ? 1 : 0}
                    onClick={() => {
                      // @ts-ignore
                      inputRef?.current?.focus();
                    }}
                  >
                    {
                    emails.map((e, index) => (
                      <StyledText
                        key={e}
                      >
                        {e}
                        <CloseIcon
                          onClick={() => handleDelete(index)}
                        />
                      </StyledText>
                    ))
                  }
                    <StyledInput
                      ref={inputRef}
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </Wrapper>
                  {errors?.emails?.message && <ErrorMessage message={errors?.emails?.message} />}
                </Box>

                <StyledTemplateWrapper>
                  <p>Template:</p>
                  <Select
                    name="template"
                    options={options}
                  />
                </StyledTemplateWrapper>

                {
                  buttonLink && (
                    <StyledTemplateWrapper>
                      <p>Button link:</p>
                      <Input
                        disabled
                        {...register('buttonLink')}
                        width="100%"
                        label=""
                      />
                    </StyledTemplateWrapper>
                  )
                }
                {
                  buttonText && (
                    <StyledTemplateWrapper>
                      <p>Button text:</p>
                      <Input
                        disabled
                        {...register('buttonText')}
                        width="100%"
                        label=""
                      />
                    </StyledTemplateWrapper>
                  )
                }
                <Textarea
                  disabled
                  height="160px"
                  placeholder="Text..."
                  {...register('textTemplate')}
                />
                <Button type="submit" disabled={!template}>Send Email</Button>
                <GlobalUpdatesMessage {...isSuccessMsg} />
              </>
            )
        }
      </Stack>
    </FormProvider>
  );
};

export default SendEmailDialog;
