import { useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectOrderDetails } from '@features/orders/order/selectors';
import { getOrderDetails, updateOrderInfoDetails } from '@features/orders/order/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { getVendorsListThunk } from '@features/vendors/actions';
import { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { SUCCESS_MESSAGE } from '@utils/messages';

import { preparingFormData, preparingRequestPayload } from './utils/helper';
import { orderDetailsValidation } from './utils/yupValidation';
import { IOrderForm } from './utils/formPayloadType';
import { DropShipShipToEnum, IVendorOptions } from './utils/types';

export const useOrderDetailsLogic = () => {
  const { order } = useAppSelector(selectOrderDetails);
  const { jobs } = order;
  const dispatch = useAppDispatch();
  const [vendorsList, setVendorsList] = useState<IVendorOptions[]>([]);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });
  const collection = preparingFormData(jobs);

  const [type, setType] = useState<DropShipShipToEnum>(DropShipShipToEnum.EMPTY);
  const [open, setOpen] = useState<boolean>(false);
  const [shippingLink, setShippingLink] = useState<string>('');

  const handleOpen = (t: DropShipShipToEnum) => {
    setOpen(true);
    setType(t);
  };

  const handleClose = () => {
    setOpen(false);
    setType(DropShipShipToEnum.EMPTY);
    setShippingLink('');
  };

  const getVendors = async () => {
    try {
      const vendors = await dispatch(getVendorsListThunk()).unwrap();

      const options = vendors.map((vendor) => ({
        optionName: vendor.name,
        value: vendor.id,
      }));

      setVendorsList(options as IVendorOptions[]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getVendors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<{ jobs: IOrderForm[] }>({
    resolver: yupResolver(orderDetailsValidation as any),
    defaultValues: { jobs: collection },
  });

  const onSubmit = async (data: { jobs: IOrderForm[] }) => {
    try {
      const payload = {
        id: order.id,
        payload: preparingRequestPayload({ jobs: data.jobs }),
      };

      await dispatch(updateOrderInfoDetails(payload)).unwrap();
      dispatch(getOrderDetails({ id: order.id })).unwrap();
      setIsSuccessMsg({ msg: SUCCESS_MESSAGE.update, status: 200 });
    } catch (error: any) {
      setIsSuccessMsg({ msg: '', status: null });

      if (error && error?.statusCode === 400) {
        setIsSuccessMsg({ msg: 'Something went wrong.', status: error?.statusCode });
      }
    }
  };

  const value = useMemo(() => ({ handleOpen, setShippingLink }), []);

  return {
    open,
    jobs,
    order,
    value,
    methods,
    type,
    shippingLink,
    onSubmit,
    vendorsList,
    isSuccessMsg,
    handleClose,
  };
};
