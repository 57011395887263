import { ISet } from '@features/orders/order/types';

import { Artwork, Proof, ProofCustomer } from './components';
import {
  SetsContainer,
  BoxInformation,
  Title,
  StyledBox,
  SetNameWrapper,
} from './styled';

interface SetsProps {
  setsList: ISet[];
  jobIndex: number;
  shipmentIndex: number;
}

const Sets = ({
  setsList,
  jobIndex,
  shipmentIndex,
}: SetsProps) => {
  if (!setsList?.length) {
    return null;
  }

  return setsList.map((set, setIndex) => {
    const {
      id,
      shipmentId,
      backArtWork,
      frontArtWork,
      created,
      proofCustomerLogs,
    ...setRest
    } = set;

    return (
      <SetsContainer key={`${shipmentId} ${id}`}>
        <SetNameWrapper>
          <p>{setRest.name}</p>
          <span>Job Id</span>
        </SetNameWrapper>
        <BoxInformation>
          <StyledBox>
            <Title>
              ARTWORK
            </Title>
            <Artwork
              created={created}
              jobIndex={jobIndex}
              setIndex={setIndex}
              shipmentIndex={shipmentIndex}
            />
          </StyledBox>
          <StyledBox>
            <Proof
              setId={id}
              jobIndex={jobIndex}
              setIndex={setIndex}
              shipmentIndex={shipmentIndex}
              backProofStatus={setRest.backProofStatus}
              frontProofStatus={setRest.frontProofStatus}
            />
          </StyledBox>
          <StyledBox>
            <Title>
              PROOF UPLOAD & STATUS LOG
            </Title>
            <ProofCustomer
              proofCustomerLogs={proofCustomerLogs}
            />
          </StyledBox>
        </BoxInformation>
      </SetsContainer>
    );
  });
};

export default Sets;
