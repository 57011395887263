import { memo, useContext } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@containers/common/Button';
import { useNavigate } from 'react-router-dom';
import StyledTypography from '@containers/common/StyledTypography';
import { OrderKindsContext } from '@containers/Orders/Orders';
import PAGE_ROUTES from '@routes/routingEnum';
import { KindType, getOrderLinkByKind } from '@containers/Orders/Orders/helper';
import { UseFormReset } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { selectRowsArray } from '@features/orders/openOrders/slice';

import { IFiltersForm } from '../helpers';

interface SearchBtnForOrdersPageProps {
  reset: UseFormReset<IFiltersForm>;
}

const SearchBtnForOrdersPage = ({ reset }: SearchBtnForOrdersPageProps) => {
  const initialState = { status: '', searchTerm: '', dateFrom: '', dateTo: '' };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobsKind = useContext(OrderKindsContext);

  const handleReset = () => {
    reset(initialState);
    dispatch(selectRowsArray([]));

    if (jobsKind) {
      const path = getOrderLinkByKind(jobsKind as KindType);

      navigate(`${path}`);

      return;
    }

    navigate(PAGE_ROUTES.OPEN_ORDERS);
  };

  return (
    <Stack direction="row" gap="16px" alignItems="center">
      <Button width="120px" type="submit">Search</Button>
      <StyledTypography
        onClick={handleReset}
        color="grey"
        variant="body3"
      >
        Reset Filters
      </StyledTypography>
    </Stack>
  );
};

export default memo(SearchBtnForOrdersPage);
