import {
  createRequiredTextWidthValidation,
  createTextWidthValidation,
  numberValidation,
} from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  id?: string;
  name: string;
  attributeCategory: string;
  nickname: string;
  showInCustomEstimates?: boolean;
  defaultPrice?: number | null;
  fouroverCode?: string;
}

export const defaultValues = {
  name: '',
  attributeCategory: '',
  nickname: '',
  showInCustomEstimates: false,
  defaultPrice: null,
  fouroverCode: '',
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
  attributeCategory: createRequiredTextWidthValidation('Attribute category', 255),
  nickname: createRequiredTextWidthValidation('Attribute nickname', 255),
  defaultPrice: numberValidation.nullable().max(999999999, 'The maximum number of digits is 9'),
  fouroverCode: createTextWidthValidation(250),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Attribute Category',
    field: 'attributeCategory',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Name',
    field: 'name',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Attribute Nickname(used internally only)',
    field: 'nickname',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Show in Custom Estimates',
    field: 'showInCustomEstimates',
    type: InputTypes.checkbox,
    isRequired: false,
  },
];
