import moment from 'moment';
import Input from '@containers/common/Input';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import { IOrder } from '@features/orders/openOrders/types';
import { selectRow } from '@features/orders/openOrders/slice';
import TCell from '@containers/common/Table/components/TCell';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectOpenOrders } from '@features/orders/openOrders/selectors';
import { UpdateShipmentPayload } from '@features/orders/order/types';
import { DATE_FORMAT, getCDNImagePath } from '@utils/helpers';
import defaultArtwork from '@assets/images/default-artwork.png';
import { OrderListUpdateRequestFieldKeys, OrderStatusesEnum } from '@features/orders/order/enums';
import ErrorMessage from '@containers/common/ErrorMessage';
import { PrevState } from '@utils/prevState';

import OrderShipments from '../OrderShipments';
import {
  StyledSelect,
  OrderIdLink,
  StyledOrderTableRow,
  StyledTCell,
} from '../styled';
import { IVendorOptions, ValidMessageState } from '../useOpenOrderListLogic';

interface OrderItemInListProps extends IOrder {
  statusOptions: {
    value: string;
    optionName: string;
  }[];
  vendorsList: IVendorOptions[];
  validMessageState: ValidMessageState;
  beginningDebounceForEachChange: (key: OrderListUpdateRequestFieldKeys, value: string) => void;
  beginningDebounceForShipmentChanges: (args: UpdateShipmentPayload) => void;
}

const OrderItemInList = ({
  id,
  date,
  cost,
  billTo,
  orderId,
  status,
  vendorId,
  vendorName,
  mailPo,
  vendorJob,
  shipments,
  jobNumber,
  vendorsList,
  hasOpenTasks,
  statusOptions,
  validMessageState,
  beginningDebounceForEachChange,
  beginningDebounceForShipmentChanges,
  ...rest
}: OrderItemInListProps) => {
  const { openJobsSelectedRows } = useAppSelector(selectOpenOrders);
  const dispatch = useAppDispatch();

  const artworkSrc = rest.frontArtWork || rest.backArtWork;

  let artwork = '';

  if (artworkSrc) {
    const url = getCDNImagePath(artworkSrc);

    if (
      url.endsWith('.jpeg') ||
      url.endsWith('.jpg') ||
      url.endsWith('.png') ||
      url.endsWith('.webp')
    ) {
      artwork = url;
    } else {
      artwork = defaultArtwork;
    }
  }

  const redColorStatuses = [
    OrderStatusesEnum.Received,
    OrderStatusesEnum.AwaitingArtwork,
    OrderStatusesEnum.AwaitingProofApproval,
    OrderStatusesEnum.Approved,
    OrderStatusesEnum.StillAwaitingProofApproval,
  ];

  return (
    <StyledOrderTableRow
      className={`${hasOpenTasks ? 'has-open-task' : ''} this-is-${status}`}
    >
      <StyledTCell text="" width="20px">
        <Checkbox
          onClick={() => {
            dispatch(selectRow(id));
          }}
          checked={openJobsSelectedRows.includes(id)}
        />
      </StyledTCell>
      <StyledTCell>
        <OrderIdLink
          onClick={() => PrevState.set({ scroll: window?.scrollY })}
          to={`/orders/${orderId}/${id}`}
        >
          #
          {jobNumber}
        </OrderIdLink>
      </StyledTCell>
      <StyledTCell>
        {artwork ? (<img src={artwork} alt="" width="68px" />) : null}
      </StyledTCell>
      <StyledTCell
        text={
          date
            ? moment(date).format(DATE_FORMAT)
            : moment().format(DATE_FORMAT)
      }
      />
      <StyledTCell text={`${billTo}`} />
      <StyledTCell>
        <StyledSelect
          id=""
          label=""
          labelId=""
          defaultValue={status}
          className={`${redColorStatuses.includes(status) ? 'red-statuses' : ''}`}
          onChange={(e) => {
            beginningDebounceForEachChange(
              OrderListUpdateRequestFieldKeys.status,
              `${e.target.value}`,
            );
          }}
        >
          <MenuItem value="">
            <Typography variant="body3">Select...</Typography>
          </MenuItem>
          {statusOptions.map((item) => <MenuItem key={item.value} value={item.value}>{item.optionName}</MenuItem>)}
        </StyledSelect>
      </StyledTCell>
      <StyledTCell>
        <Input
          defaultValue={vendorJob}
          name="vendorJob"
          placeholder="Enter..."
          onChange={(e) => {
            beginningDebounceForEachChange(
              OrderListUpdateRequestFieldKeys.vendorJob,
              e.target.value,
            );
          }}
        />
        {
          validMessageState?.where === OrderListUpdateRequestFieldKeys.vendorJob &&
          validMessageState?.rowId === id &&
          <ErrorMessage message={validMessageState.message} />
        }
      </StyledTCell>
      <StyledTCell>
        <StyledSelect
          value={vendorId || 'Select'}
          defaultValue="Select"
          sx={{ width: '150px', height: '45px' }}
          onChange={(el) => {
            beginningDebounceForEachChange(
              OrderListUpdateRequestFieldKeys.vendorId,
              el.target.value as string,
            );
          }}
        >
          <MenuItem value="Select">
            <Typography variant="body3">Select</Typography>
          </MenuItem>
          {vendorsList.map(({ optionName, value }) => (
            <MenuItem key={value} value={value} selected={value === vendorId}>
              <Typography variant="body3">{optionName}</Typography>
            </MenuItem>
          ))}
        </StyledSelect>

      </StyledTCell>
      <StyledTCell width="100px">
        <Input
          disabled
          name="cost"
          defaultValue={cost}
          sx={{
            minWidth: '80px',
          }}
        />
      </StyledTCell>
      <StyledTCell>
        <OrderShipments
          shipments={shipments}
          onChange={(shipmentsUpdatePayload: UpdateShipmentPayload) => {
            beginningDebounceForShipmentChanges(shipmentsUpdatePayload);
          }}
        />
      </StyledTCell>
      <StyledTCell>
        {mailPo && (
          <Input
            name="test"
            defaultValue={mailPo}
            placeholder="Enter..."
            onChange={(e) => {
              beginningDebounceForEachChange(
                OrderListUpdateRequestFieldKeys.mailingPO,
                e.target.value,
              );
            }}
            sx={{
              minWidth: '80px',
            }}
          />
        )}
      </StyledTCell>
    </StyledOrderTableRow>
  );
};

export default OrderItemInList;
