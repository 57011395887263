import Table from '@containers/common/Table';
import { StyledTableRow } from '@containers/common/Table/styled';
import TCell from '@containers/common/Table/components/TCell';
import { IOrder } from '@features/orders/order/types';
import { priceFormatting } from '@utils/helpers';

import { TableWrapper } from './styled';

const SubTotal = ({ order }: {order: IOrder }) => {
  const subTotal = order?.subTotal || order?.subTotal === 0 ? priceFormatting(order?.subTotal) : '';
  const tax = order?.tax || order?.tax === 0 ? priceFormatting(order?.tax) : '';
  const shipping = order?.shipping || order?.shipping === 0 ? priceFormatting(order?.shipping) : '';
  const total = order?.total || order?.total === 0 ? priceFormatting(order?.total) : '';
  const charity = order?.charity || '';
  const discountAmount = order?.discountAmount ? `${order?.discountAmount}` : '';

  const formatDiscount = (value: string) => {
    const fixedValue = Number(value).toFixed(2);

    return +value < 0 ? `- $ ${Math.abs(+fixedValue)}` : `$ ${fixedValue}`;
  };

  return (
    <TableWrapper>
      <Table
        title=""
        headCells={[
          { label: 'SUBTOTAL' },
          { label: 'TAX' },
          { label: 'SHIPPING' },
          { label: 'ORDER DISCOUNT' },
          { label: 'TOTAL' },
          { label: 'GIVE BACK' },
        ]}
      >
        <StyledTableRow>
          <TCell text={subTotal} />
          <TCell text={tax} />
          <TCell text={shipping} />
          <TCell text={formatDiscount(discountAmount)} />
          <TCell text={total} />
          <TCell text={charity} />
        </StyledTableRow>
      </Table>
    </TableWrapper>
  );
};

export default SubTotal;
