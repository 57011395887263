import {
  createOptionalTextWidthValidation,
  createRequiredTextWidthValidation,
  numberValidation,
} from '@utils/schemas';
import * as yup from 'yup';

export const requiredIfItIsNotMailingService = yup.object().shape({
  phone: createRequiredTextWidthValidation('Phone', 255, undefined, 'Please enter a phone number.'),
  city: createOptionalTextWidthValidation('City', 255),
  company: createOptionalTextWidthValidation('Company', 255),
  country: createRequiredTextWidthValidation('Country', 255, undefined, 'Please select a country.'),
  address1: createRequiredTextWidthValidation('Address 1', 255, undefined, 'Please enter a valid street address.'),
  address2: createOptionalTextWidthValidation('Address 2', 255),
  fullName: createRequiredTextWidthValidation('Full Name', 255, undefined, 'Please enter your full name.'),
  state: createRequiredTextWidthValidation('State', 255, undefined, 'Please select a state or province.'),
  zipCode: createRequiredTextWidthValidation('Zip code', 10, 4),
});

// function hasAnyValue(obj: Record<string, any>) {
//   return Object.values(obj || {})?.some((value) => value !== null && value !== undefined);
// }

const shipmentsSchema = yup.array().of(
  yup.object().shape({
    id: yup.string().uuid().required(),
    trackingNumber: createOptionalTextWidthValidation('Tracking Number', 255),
    trackingCarrier: yup.string().optional(),
    sets: yup.array().of(
      yup.object().shape({
        id: yup.string().uuid().required(),
        backProof: yup.string().nullable(),
        frontProof: yup.string().nullable(),
        backArtWork: yup.string().nullable(),
        frontArtWork: yup.string().nullable(),

        // backProofNotes: createOptionalTextWidthValidation('Proof Notes', 2000),
        // frontProofNotes: createOptionalTextWidthValidation('Proof Notes', 2000),
      }),
    ).required(),

    // isMailing: yup.boolean().required(),

    // ### ### ### ### ### ### ### ### ### ### ###
    // Check this logic ion new design
    // shipTo: yup.object().when('isMailing', ([isMailing], schema, collection) => {
    //   const checkingValues = hasAnyValue(collection.value);
    //   if (checkingValues) {
    //     return schema.shape(requiredIfItIsNotMailingService);
    //   }
    //   if (isMailing) {
    //     return schema.shape(optionalIfItIsMailingService);
    //   }
    //   return schema.shape(optionalIfItIsMailingService);
    // }),
    // dropShip: yup.object().when(['isMailing', 'isDropShip'], ([isMailing, isDropShip], schema) => {
    //   return !isMailing && isDropShip
    //     ? schema.shape(requiredIfItIsNotMailingService) : schema.shape(optionalIfItIsMailingService);
    // }),
  }),
).required();

const jobsSchema = yup.object().shape({
  id: yup.string().required(),
  name: createRequiredTextWidthValidation('Name', 255),
  notes: createOptionalTextWidthValidation('Notes', 2000),
  status: yup.string().required(),

  mailingPO: createOptionalTextWidthValidation('Mailing PO', 255),
  mailingFile: yup.string().optional().nullable(),
  postageToVendor: yup.boolean().required(),
  customerPostage: yup.boolean().required(),
  postageFromVendor: yup.boolean().required(),
  shipments: shipmentsSchema,
  attributes: yup
  .array()
  .of(
    yup
    .object()
    .shape({
      attributeId: yup.string().required(),
      attributePrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
      attributeName: yup.string().required(),
    }),
  ).required(),
  editablePrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableShipping: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableTax: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableTotal: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  quantityPrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  sendEmail: yup.boolean().required(),
  emails: yup
  .array()
  .of(createRequiredTextWidthValidation('emails', 255))
  .required()
  .min(1, 'Must contain at least one email address'),
  textTemplate: createRequiredTextWidthValidation('Text template', 500),
  emailTemplateId: createRequiredTextWidthValidation('Email Template', 255),
  vendor: createOptionalTextWidthValidation('Vendor', 255),
  vendor2: createOptionalTextWidthValidation('Vendor 2', 255),
  vendor3: createOptionalTextWidthValidation('Vendor 3', 255),
  vendorJob: createOptionalTextWidthValidation('Vendor Job', 255),
  vendorJob2: createOptionalTextWidthValidation('Vendor Job2', 255),
  vendorJob3: createOptionalTextWidthValidation('Vendor Job3', 255),
});

export const orderDetailsValidation = yup.object().shape({
  jobs: yup.array().of(jobsSchema).required(),
});
