import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@services/RequestService";
import { customErrorHandling } from "@utils/errorHandler";
import { AxiosData } from "@utils/globalTypes";
import { AxiosResponse } from "axios";
import { Filters, constructQueryStringExceptionWithOffer } from "@utils/helpers";

import { IOrder } from "./types";
import { IShipTo, UpdateOrderPayload, UpdateShipmentPayload } from "../order/types";

const prefix = "/orders";

export const getOrdersThunk = createAsyncThunk<
	IOrder[],
	Filters,
	{ rejectValue: AxiosData }
>("orders/all", async (searchingData, thunkAPI) => {
  try {
    const queryParams = constructQueryStringExceptionWithOffer(searchingData);

    const { data: { data } } = await http.get<AxiosResponse<IOrder[]>>(`${prefix}?${queryParams}`);

    return data;
  } catch (error) {
    const errorInfo = customErrorHandling(error);

    return thunkAPI.rejectWithValue(errorInfo);
  }
});

export const updateOrderInfo = createAsyncThunk<void, UpdateOrderPayload, {rejectValue: AxiosData}>(
  'orders/update',
  async ({ id, payload }, thunkAPI) => {
    try {
      await http.put(`${prefix}/jobs/${id}`, payload);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const updateShipmentInfo = createAsyncThunk<void, UpdateShipmentPayload, {rejectValue: AxiosData}>(
  'orders/update-shipment',
  async ({ id, ...payload }, thunkAPI) => {
    try {
      await http.put(`${prefix}/shipments/${id}/tracking`, payload);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const updateJobsStatuses = createAsyncThunk<void, { status: string; ids: string[] }, {rejectValue: AxiosData}>(
  'orders/update-jobs-statuses',
  async (payload, thunkAPI) => {
    try {
      await http.put(`${prefix}/jobs/status`, payload);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const updateOrderShipToInfo = createAsyncThunk<void, IShipTo, {rejectValue: AxiosData}>(
  'orders/update-shipTo',
  async ({ id, ...payload }, thunkAPI) => {
    try {
      await http.put(`${prefix}/shipments/${id}/shipping-address`, payload);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const updateOrderDropToInfo = createAsyncThunk<void, IShipTo, {rejectValue: AxiosData}>(
  'orders/update-dropTo',
  async ({ id, ...payload }, thunkAPI) => {
    try {
      await http.put(`${prefix}/shipments/${id}/dropship-address`, payload);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
