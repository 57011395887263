export interface IUploadFileProps {
  file: FileStateType;
  url: string;
}

export interface ICreateAWSLinkResponse {
  putUrl: string;
  path: string;
}

export type FileStateType = FileList | null;

export const getMimeType = (filename: string): string => {
  const extension = filename.split('.').pop()?.toLowerCase();

  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'webp':
      return 'image/webp';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream'; // Fallback type
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = async (file: any, filePath: any) => {
  try {
    const mimeType = getMimeType(filePath.path);

    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType,
      },
      withCredentials: false,
      body: file,
    };

    await fetch(filePath.putUrl, options);
  } catch (error) {
    console.log(error);
  }
};

export const getUploadUrl = async (file: File) => {
  const fileDetails = [file].map(({ name }) => {
    const idx = name.lastIndexOf('.');
    const fileName = name.substring(0, idx).replace(/[ #+?%\\]/g, '');
    const extension = name.substring(idx + 1);

    return {
      name: fileName,
      extension,
    };
  });

  let token = localStorage.getItem('ACCESS_TOKEN') || '';

  token = token?.replace(/"/g, '');

  const response = await fetch(
    process.env.NEXT_PUBLIC_S3_UPLOAD_API_URL || '',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(fileDetails),
    },
  );

  const { data } = await response.json();

  return { fileList: data };
};

export const imgExtensions = [
  '.png',
  '.jpg',
  '.jpeg',
  '.ai',
  '.pdf',
  '.indd',
  '.eps',
  '.psd',
  '.cdr',
];

export const isValidFileExtension = (file: File) => {
  // dont have file types
  if (file.name.endsWith('.indd') || file.name.endsWith('.cdr')) {
    return true;
  }

  if (
    file.type === 'image/png' ||
    file.type === 'image/jpg' ||
    file.type === 'image/jpeg' ||
    file.type === 'application/postscript' ||
    file.type === 'application/pdf' ||
    file.type === 'application/x-photoshop' || // .psd
    file.type === 'image/vnd.adobe.photoshop'
  ) {
    return true;
  }

  return false;
};
