import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Content = styled(Box)(() => ({
  padding: '17px 16px',
  display: 'grid',
  height: '142px',
  gap: '10px',
  gridTemplateRows: '1fr 1fr',
}));

export const Underlined = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  cursor: 'pointer',

  p: {
    textDecoration: 'underline',
    color: '#1D73E3',
  },
}));

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));
