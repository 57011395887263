import { ChangeEvent, memo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import PAGE_ROUTES from '@routes/routingEnum';
import { constructQueryString } from '@utils/helpers';
import { paginationLimit } from '@utils/constants';
import { GlobalQueryString } from '@utils/globalTypes';
import { TableProps } from '@mui/material/Table';

import { StyledPagination } from './styled';

export interface IPagination extends TableProps {
  total?: number;
  path?: keyof typeof PAGE_ROUTES;
}

const Pagination = ({ total, path }: IPagination) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { page: currentPage = 0 } = params as GlobalQueryString;

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    const pageMinus = page - 1;
    const queryParams = constructQueryString({
      ...params,
      page: pageMinus.toString(),
      offset: (pageMinus * paginationLimit).toString(),
    });

    path && navigate(`${PAGE_ROUTES[path]}?${queryParams}`);
  };

  return (
    <StyledPagination
      count={total ? Math.ceil(total / paginationLimit) : 11}
      defaultPage={1}
      page={+currentPage + 1}
      onChange={handlePageChange}
    />
  );
};

export default memo(Pagination);
