import { styled } from '@mui/material/styles';
import { Box, Select, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '50px',
  alignItems: 'flex-end',
  gridTemplateColumns: '1fr 2px 1fr 2px 1fr',

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
  },
}));

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  maxWidth: '300px',
}));

export const StyledSelect = styled(Select)(() => ({
  width: '100%',
  maxHeight: '30px',
  background: 'white',
  margin: '16px 0 8px 0',

  // special case
  div: {
    padding: '5px 16px',
  },
}));

export const StyledWrapper = styled(Select)(() => ({
  padding: '5px 16px',
}));

export const StyledTypography = styled(Typography)(() => ({
  fontSize: '12px',
}));

export const StyledLine = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  height: '100px',
  width: '1px',
  background: theme.palette.grey[500],

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));
